import React, { useState } from 'react';
import './SquareCheckboxInput.css';


function SquareCheckboxInput(props) {
    const [checked, setChecked] = useState(false);

    const checkedEvent = (e) => {
        console.log(e);
        console.log('checked event');
        if (checked === true) {
            setChecked(false);
            props.action(false);
            return;
        }
        setChecked(true);
        props.action(true);
    }

    return (
        <div className="we__sqcb__input">
            <label className="we__sqcb__container" htmlFor='sq-check'>
                <input type="checkbox" id="sq-check" onChange={checkedEvent}/>
                <span className="we__sq__checkmark"></span>
            </label>
        </div>
    );
}

export default SquareCheckboxInput;