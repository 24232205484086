import React, { useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { AppContextProvider } from './AppContext/AppContext';
import './App.css';
import LoginView from './Components/LoginView/LoginView';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';



function App() {
    let navigate = useNavigate();
    let params = useParams();


  
  
const setViewForUserType = (view) => {
    switch (view) {
        case 'admin':
            navigate("/admin");
            return;
        case 'app':
            navigate("/apps");
            return;
          default:
            navigate("/loginError");
            return;
      }
  }

  

  return (
    
        <div className="App">
            <LoginView loginAction={setViewForUserType}/>
        </div>
    
  );
}

export default App;
