import imgBg from '../../../../images/STOREBG.webp';
import './ContentGrid.css';

function ContentGrid({ left,mid,right }) {
  return (
    <div className="main__content__grid">
      <img src={imgBg} className="bg-image" />
      <div className='bgd__gradient'>
        {left}
        {mid}
        {right}
      </div>
    </div>
  );
}

export default ContentGrid;