import React from 'react';

const Button = ({ icon,className,title,...props}) => {
    return (
        <button type="button" className={`glds__btn ${className}`} {...props}>
            {icon}{title}
        </button>
    );
};

export default Button;