import React, { useRef,useEffect,useState } from 'react';
import { Save,XCircle } from 'react-bootstrap-icons';
import { createFormDataObj } from '../../../../Utilities';
import { useLocalStorage } from '../../../../useLocalStorage';
import loader from '../../../../svg/tail-spin.svg';
import Button from '../../../Button';
import './AddProductForm.css';
import styles from'./AddProductForm.module.css';

const inputBorderColor = '#ced4da';

const productTypes = [{value:"app",type:"Application"},{value:"srv",type:"Service"}];
const seatSelectOptions = Array.from(Array(101).keys());

const AddProductForm = ({ formData,submitAction,altAction,readOnly }) => {
    const [session, setSession] = useLocalStorage('session');
    const [userId, setUserId] = useLocalStorage('uid');

    const [isLoading, setIsLoading] = useState(true);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);


    const [checkedStates, setCheckedStates] = useState([]);

    const [currentAgent, setCurrentAgent] = useState({agent:"Agent not set",passphrase:"Agent not set"});
    const [currentProduct, setCurrentProduct] = useState({});
    const [currentLicense, setCurrentLicense] = useState({});

    const [showLicenseForm, setShowLicenseForm] = useState(false);
    const [showServiceForm, setShowServiceForm] = useState(false);
    const [showLicenseSeatDetails, setShowLicenseSeatDetails] = useState(false);



    let org_id = formData.org_id;
    

    const [products, setProducts] = useState([]);
    const [users, setUsers] = useState([]);
    const [orgAgents, setOrgAgents] = useState([]);

    const [numberOfSeats, setNumberOfSeats] = useState({
        total: 0,
        used: 0
    });

    const product_id = useRef("");
    const nameTFRef = useRef(null);
    const srpTFRef = useRef(null);
    const descTFRef = useRef(null);

    

    const hostNameRef = useRef(null);
    const hostpwRef = useRef(null);
    const inPathRef = useRef(null);
    const outPathRef = useRef(null);
    const arcPathRef = useRef(null);
    const hostUserRef = useRef(null);
    const hostPortRef = useRef(null);

    const productTypeSelectRef = useRef(null);

    const createLicense = (_orgID,_productID,_seats) => {
        // debugger;
        console.log(`SESSION: ${session}`);
        const fd = createFormDataObj({
            action: "create_license",
            session,
            uid: userId,
            org_id: _orgID,
            product_id: _productID,
            seats: _seats,

        });
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => { 
            console.table(`API RESPONSE: Success=${result.success}, Message=${result.message}`);                   
            if(result.success === true){
                console.log(result.message);
                console.log(result);
                setCurrentLicense(result.data);
                return;
            }
        })
        .catch(error => {
            console.error('Error:',error);
        })
    }
    const updateLicense = () => {

    }

    const fetchProducts = () => {
        let fd = createFormDataObj({
            action: 'list_products',
            session,
            uid: userId
        });
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => { 
            console.table(`API RESPONSE: Success=${result.success}, Message=${result.message}`);    
            if(result.success === true){                
                setProducts(result.data);
            }
        })
        .catch(error => {
            console.error('Error:',error);
        })
    }
    const fetchUsers = () => {
        let fd = createFormDataObj({
            action: 'list_users',
            session,
            uid: userId,
            org_id: org_id
        });
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => { 
            console.table(`API RESPONSE: Success=${result.success}, Message=${result.message}`);    
            if(result.success === true){                
                setUsers(result.data);
                let tmpArr  = new Array(result.data.length).fill(false);
                setCheckedStates([...tmpArr]);
            }
        })
        .catch(error => {
            console.error('Error:',error);
        })
    }
    const fetchAgents = () => {
        let fd = createFormDataObj({
            action: 'get_agents',
            session,
            uid: userId,
            org_id: formData.org_id
        });
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => { 
            console.table(`API RESPONSE: Success=${result.success}, Message=${result.message}`);    
            if(result.success === true){                
                let tmp = result.data;
                setOrgAgents(result.data);
            }
        })
        .catch(error => {
            console.error('Error:',error);
        })
    }

    useEffect(() => {
        fetchProducts();
        fetchUsers();
        fetchAgents();
    },[]);



    const closeModalForm = e => {
        console.log(e);
        console.log('Add user form close form modal method');
      
        if (altAction) {
            altAction();
            return;
        }
        submitAction();
    }
  

    const submitForm = e => {
        e.preventDefault();
        let productType = currentProduct.type;

        let fd;
        

        if (productType === "svc"){

            fd = {
                type: productType,
                org_id: formData.org_id,
                product_id: currentProduct.id,
                seats: 1,
                details: JSON.stringify({
                    agent_id: currentAgent.id,
                    host: hostNameRef.current.value,
                    hostname: hostUserRef.current.value,
                    password: hostpwRef.current.value,
                    out_path: outPathRef.current.value,
                    in_path: inPathRef.current.value,
                    archive_path: arcPathRef.current.value,
                    port: hostPortRef.current.value
                })
            };


            let svcData = {
                product_id: currentProduct.id,
                license_id: currentLicense.id,
                agent_id: currentAgent.id,
                host: hostNameRef.current.value,
                hostname: hostUserRef.current.value,
                password: hostpwRef.current.value,
                out_path: outPathRef.current.value,
                in_path: inPathRef.current.value,
                archive_path: arcPathRef.current.value,
                port: hostPortRef.current.value
            }
        }
        if (submitAction){
            submitAction(fd,'create_license');
        }
    }




    const productSelectChangeEvent = (e) => {
        console.log(e);
        let selIndx = e.target.selectedIndex;
        if (selIndx === 0){
            setShowLicenseForm(false);
            setShowServiceForm(false);
            setCurrentProduct({});
            return;
        }
        setCurrentProduct(products[selIndx - 1]);
        
        product_id.current = products[selIndx - 1].id;
        if (products[selIndx - 1].type === "app"){
            console.log('APP TYPE PRODUCT SELECTED');
            console.log(products[selIndx - 1]);
            setShowServiceForm(false);
            setShowLicenseForm(true);
            return;
        }
        console.log('SERVICE TYPE PRODUCT SELECTED');
        console.log(products[selIndx - 1]);
        setShowLicenseForm(false);
        setShowServiceForm(true);
        // createLicense(org_id,products[selIndx - 1].id,1);
    }
    const seatCountSelectChangeEvent = (e) => {
        console.log(e);
        console.log(`Number of seats: ${e.target.value}`);
        if (e.target.value === 0 || e.target.value === "default"){
            setNumberOfSeats({...numberOfSeats,total:0});
            setShowLicenseSeatDetails(false);
            return;

        }        
        setNumberOfSeats({...numberOfSeats,total:e.target.value,used:0});        
        setShowLicenseSeatDetails(true);
        // createLicense(org_id,product_id.current,e.target.value);
    }
    const agentSelectChangeEvent = (e) => {
        console.log(e);
        if (e.target.value === "default") {
            setIsDisabled(true);
            return;
        }
        let agent = orgAgents[e.target.value];
        
        setCurrentAgent(agent);
        setIsDisabled(false);
        console.log(`Store agent selected: ${orgAgents[e.target.value].store}`);
    }

    const checkboxClickEvent = (e,eleIndex) => {
        
        let checkedStatus = checkedStates[eleIndex];        
        let checkboxVal = e.target.dataset.value;

        debugger;

        let li = e.target.closest('li');
        let ele = li.querySelector('.license__user__item__loading');
        let loadingEle = li.querySelector('.license__user__item__loading');
        let bannerEle = li.querySelector('.license__user__item__banner');
        li.classList.toggle('is-loading');
        
        // setDisabledBtn(true);
        
        
        let updatedCheckState = checkedStates.map((check,index) => {
            return index === eleIndex ? !check : check;
        })
        
        
        if (checkedStatus){
            setCheckedStates(updatedCheckState);
        //     let fd = createFormDataObj({
        //         action: 'delete_license_seat',
        //         session,
        //         uid: userId,
        //         delete_id: parseInt(checkboxVal)
        //     });
        //     apiDataFetch(window.API_URL,{method:"POST",body:fd}).then(result => {
        //         if (result.success === true){
        //             const intv = setTimeout(() => {
        //                 li.classList.toggle('is-loading');
        //                 bannerEle.firstElementChild.innerText = "License Removed!"
        //                 bannerEle.classList.toggle('success');
        //                 bannerEle.classList.toggle('is-visible');
        //                 const intv2 = setTimeout(() => {
        //                     bannerEle.classList.toggle('is-visible');
        //                     setDisabledBtn(false);
        //                 },3000);
        //                 clearTimeout(intv);
        //             },1000)
        //             return;
        //         }
        //     });
            
            // let res = viewData.seats.users.filter(user => parseInt(user) === parseInt(checkboxVal));
        //     debugger;
            // let tmpA = [];
            // if (res.length !== 0){
                // res.forEach(user => {
                    // tmpA.push(user.id);
                // });
            // }
        //     setViewData({...viewData,seats:{...viewData.seats,users:tmpA}});
        //     let filtered = licensedUsers.filter(fUser => parseInt(fUser.id) !== parseInt(checkboxVal));
        //     setLicensedUsers(filtered);
        //     fetchLicenseForId(viewData.id);
            return;
        }
        
        // if (viewData.num_seats === viewData.used_seats){
        //     li.classList.toggle('is-loading');
        //     li.classList.toggle('is-error');
        //     bannerEle.firstElementChild.innerText = "ERROR: No availble licenses remain!"
        //     bannerEle.classList.toggle('is-error');
        //     bannerEle.classList.toggle('is-visible');
        //     const intv = setTimeout(() => {
        //         bannerEle.classList.toggle('is-error');
        //         bannerEle.classList.toggle('is-visible');
        //         setDisabledBtn(false);
        //         clearTimeout(intv);
        //     },1000);
        //     return;
        // }
        
        setCheckedStates(updatedCheckState);
        
        // let fd = createFormDataObj({
        //     action: 'create_license_seat',
        //     session,
        //     uid: userId,
        //     license_id: viewData.id,
        //     user_id: parseInt(checkboxVal)
        // });
        // apiDataFetch(window.API_URL,{method:"POST",body:fd}).then(result => {
        //     if (result.success === true){
        //         fetchLicenseForId(viewData.id);
        //         const intv = setTimeout(() => {
        //             li.classList.toggle('is-loading');
        //             bannerEle.firstElementChild.innerText = "License Added!"
        //             bannerEle.classList.toggle('is-success');
        //             bannerEle.classList.toggle('is-visible');
        //             const intv2 = setTimeout(() => {
        //                 bannerEle.classList.toggle('is-success');
        //                 bannerEle.classList.toggle('is-visible');
        //                 setDisabledBtn(false);
        //                 clearTimeout(intv2);                        
        //             },1000);
        //             clearTimeout(intv);
        //         },1000)
        //         return;
        //     }
        // }).catch(error => {
        //     console.error('Error:',error);
        // })
        
        // let filtered = users.filter(fUser => parseInt(fUser.id) === parseInt(checkboxVal));
        // let tmpA = [...viewData.seats.users,...[parseInt(checkboxVal)]];
        // setViewData({...viewData,seats:{...viewData.seats,users:tmpA}});
        // setLicensedUsers([...licensedUsers,...filtered]);
    }

    return (
        <form id="add_product_modal-form" className={styles['modal-form']}>

            <div className={styles['select-row']}>
                <div className={styles['select-container']}>
                    <div className={'form-floating pt-2 pb-2'}>
                        <select className="form-select" aria-label="Select product type" onChange={productSelectChangeEvent}>
                            <option value={'default'}>Choose Product</option>
                            {products.map((product,index) => {
                                return (
                                    <option key={product.id} value={product.id}>{product.name}</option>
                                )
                            })}                                    
                        </select>                            
                        <label htmlFor="org-state" aria-label='billing State'>Products</label>
                    </div>
                </div>                
            </div>

            <div className={styles['form-row']}>

               <div className={styles['add_product-content']}>

                    {/* PRODUCT LICENSE FORM HTML */}
                    {showLicenseForm && <div className={styles['product_license-form']}>

                        {/* SECTION - title of the add product form showing  */}
                        <div className='title-container'>
                            <h1>Add a license</h1>
                        </div>

                        {/* SECTION - select number of seats select */}
                        <div className={styles['seat_select-container']}>
                            
                            <div className={'form-floating mt-2 mb-1'}>
                                <select className="form-select" aria-label="Select organization" onChange={seatCountSelectChangeEvent}>
                                    <option value={'default'}></option>
                                    {seatSelectOptions.map((seat,index) => {
                                        return (
                                            <option key={`${index}-${seat}`} value={seat}>{seat}</option>
                                        )
                                    })}                                    
                                </select>                            
                                <label htmlFor="org-state" aria-label='billing State'>Number of seats</label>
                            </div> 

                        </div>
                        
                        {/* SECTION - license details */}
                        {showLicenseSeatDetails && <div className={styles['license_detail-container']}>

                            <div className={styles['seat_details-container']}>

                                <div className={styles['seat_details']}>
                                    <div>
                                        <h1>Total Seats</h1>
                                        <span>{numberOfSeats.total}</span>
                                    </div>
                                    <div>
                                        <h1>Seats Available</h1>
                                        <span>{numberOfSeats.total - numberOfSeats.used}</span>
                                    </div>
                                </div>
                            </div>

                            <div className={styles['user_details-container']}>
                                <div className={styles['user__assign-view']}>
                                    
                                    {!isReadOnly && <div className={styles['heading']}>
                                        <h2>Assign user to license</h2>
                                    </div>}
                                    <div className={styles['body']}>
                                        <div>
                                            <h4>{!isReadOnly ?  "Available users" : "Licensed users"}</h4>
                                        </div>
                                        <ul>
                                            
                                            {users.map((user,index) => {
                                                let tmp = users;  
                                                return (
                                                    <li className={styles['license__user__item']}>
                                                        
                                                        <div className={styles['license__user__item__banner']}>
                                                            <span></span>
                                                        </div>
                                                        <div className={`item-loading ${isLoading ? "is-loading" : ""}`}>
                                                            <img src={loader} />
                                                        </div>
                                                        <div className={`license__user__item__cb-wrapper ${!isReadOnly ? "" : "is-hidden"}`}>
                                                        
                                                            <label class="container" htmlFor={`custom-checkbox-${index}`} title="Add license">
                                                                <input
                                                                    type="checkbox"
                                                                    tabindex="0"
                                                                    id={`custom-checkbox-${index}`}
                                                                    checked={checkedStates[index]}
                                                                    onChange={(e) => checkboxClickEvent(e,index)}
                                                                    data-value={user.id}
                                                                />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div className='license__user__item__details-wrapper'>
                                                            <span>
                                                                {user.name}
                                                            </span>
                                                            <span>
                                                                {user.email}
                                                            </span>
                                                        </div>
                                                        {/* <span>{agent.store}</span> */}
                                                        {/* <span>{agent.location}</span> */}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                    
                                </div>
                            </div>

                        </div>}

                    </div>}

                    {showServiceForm && <div className={styles['service_license-form']}>
                        
                        {/* SECTION - title of the add product form showing  */}
                        <div className='title-container'>
                            <h1>Service Details Setup</h1>
                        </div>
                        <div className={styles['service_detail-container']}>
                            
                            <div className={styles['agent_select-container']}>
                                <div className={'form-floating mt-3 mb-3'}>
                                    <select className="form-select" id="org-agents" aria-label="Select store for service" onChange={agentSelectChangeEvent}>
                                        <option value={'default'}></option>
                                        {orgAgents.map((agent,index) => {
                                            return (
                                                <option key={`${agent.id}`} value={index}>{agent.store}</option>
                                            )
                                        })}                                    
                                    </select>                            
                                    <label htmlFor="org-agents" aria-label='Store select field'>Select store for service</label>
                                </div>
                            </div>
                                 
                            <div className={styles['panel-group']}>
                                
                                <div className={styles['panel-one']}>
                                    <div className='title-container mb-3'>
                                        <h1>Service Configuration</h1>
                                    </div>
                                    <div className={'form-floating mb-3'}>
                                        <input ref={hostNameRef} type="text" className="form-control" id="host" placeholder="Host" disabled={isDisabled}/>
                                        <label htmlFor="host" aria-label='Host field'>Host</label>
                                    </div>
                                    <div className={'form-floating mb-3'}>
                                        <input ref={hostPortRef} type="text" className="form-control" id="host-port" placeholder="Host port" disabled={isDisabled}/>
                                        <label htmlFor="host-port" aria-label='Host port field'>Host Port</label>
                                    </div>
                                    <div className={'form-floating mb-3'}>
                                        <input ref={hostUserRef} type="text" className="form-control" id="hostuser" placeholder="Host User" disabled={isDisabled}/>
                                        <label htmlFor="hostuser" aria-label='Host user field'>Host User</label>
                                    </div>
                                    <div className={'form-floating mb-3'}>
                                        <input ref={hostpwRef} type="text" className="form-control" id="hostname-pw" placeholder="Hostname password" disabled={isDisabled}/>
                                        <label htmlFor="hostname-pw" aria-label='Hostname password field'>Hostname Password</label>
                                    </div>
                                    <div className={'form-floating mb-3'}>
                                        <input ref={inPathRef} type="text" className="form-control" id="host-inbox-path" placeholder="Host inbox path" disabled={isDisabled}/>
                                        <label htmlFor="host-inbox-path" aria-label='Host inbox path'>Host Inbox Path</label>
                                    </div>
                                    <div className={'form-floating mb-3'}>
                                        <input ref={outPathRef} type="text" className="form-control" id="host-outbox-path" placeholder="Host outbox path" disabled={isDisabled}/>
                                        <label htmlFor="host-Outbox-path" aria-label='Host Outbox path'>Host Outbox Path</label>
                                    </div>
                                    <div className={'form-floating mb-3'}>
                                        <input ref={arcPathRef} type="text" className="form-control" id="host-archive-path" placeholder="Host archive path" disabled={isDisabled}/>
                                        <label htmlFor="host-archive-path" aria-label='Host archive path'>Host Archive Path</label>
                                    </div>
                                </div>

                                <div className={styles['panel-two']}>
                                    <div className='title-container mb-3'>
                                        <h1>Service API Information</h1>
                                    </div>
                                    <div>
                                        {`Store id: ${currentAgent.agent}`}
                                    </div>
                                    <div>
                                        {`App secret: ${currentAgent.passphrase}`}
                                    </div>
                                    <div>
                                        {`App key: ${currentProduct.app_key}`}
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>}

               </div>

            </div>

            <div className='button-row'>
                {/* FORM BUTTON ROW */}
                
                <div className='button-container'>
                    <Button icon={<XCircle size={16} className='btn-icon' />} title={"Cancel"} className={"danger__button"} onClick={closeModalForm}/>
                </div>    
                <div className='button-container'>
                    <Button icon={<Save size={16} className='btn-icon' />} title={"Save"} className={"accept__button"} onClick={submitForm}/> 
                </div>
                
            </div>

        </form>
    );
}

export default AddProductForm;