import React, { useRef,useEffect } from 'react';
import './GLDSFormModal.css';

const GLDSFormModal = ({ title,form,top,closeAction }) => {
    const modalRef = useRef(null);

    const clickEvent = (e) => {
        if (e.target.classList.contains('glds__form__modal__container')) { 
            closeAction(false);    
        }
    }
    const keydownEvent = (e) => {
        if (e.code.toLowerCase() === 'escape') {
            closeAction(false);
        }
    }

    useEffect(() => {
        window.addEventListener('click',clickEvent);
        window.addEventListener('keydown',keydownEvent)
        return () => {
            window.removeEventListener('keydown',keydownEvent)
            window.removeEventListener('click',clickEvent);
        }
    },[])    

    return (
        <div className='glds__form__modal' style={{top:`${top}px`}}> 

            <div className='glds__form__modal__container'>

                {/* <div className='glds__form__modal__content'> */}

                    <div className='glds__form__modal__title'>

                        <h2>{title}</h2>

                    </div>

                    <div className='add__user__form'> 

                        {form}                    

                    </div>

                {/* </div> */}

            </div>            

        </div>

    );
}

export default GLDSFormModal;