import './MenuToolbar.css';

function MenuToolbar() {
    return (
      <div className="menutoolbar">
          <h3>App Menu</h3>
      </div>
    );
  }
  
  export default MenuToolbar;