import React, { useState,useEffect,useRef } from 'react';
import { useParams } from "react-router-dom";
import { Gear,Lock, Pencil,XCircle,Save } from 'react-bootstrap-icons';
import { useLocalStorage } from '../../../../useLocalStorage';
import { createFormDataObj,apiDataFetch } from '../../../../Utilities';
import GLDSFormModal from '../../../GLDSFormModal/GLDSFormModal';
import './LicenseDetailView.css';
import DropDownSelect from '../../../DropDownSelect/DropDownSelect';
import loader from '../../../../svg/tail-spin.svg';
import UpdatePasswordForm from '../../Forms/UpdatePasswordForm/UpdatePasswordForm';
import SquareCheckboxInput from '../../../SquareCheckboxInput/SquareCheckboxInput';
import Button from '../../../Button';
import MessageBanner from '../../../MessageBanner/MessageBanner';
import WEButton from '../../../../ui/WEButton/WEButton';


const dropdownContent = [
    {
        action: 'edit',
        title: 'Edit',
        icon: <Pencil size={18} />
    }
];




const LicenseDetailView = () => {
    // Session and current user local storage vars
    const [session, setSession] = useLocalStorage('session');
    const [userId, setUserId] = useLocalStorage('uid');
    console.log(`SESSION: ${session}`);
    // React Router useParams custom hook
    const urlParams = useParams();

    // useRef hook vars
    const navIconRef = useRef(null);
    const nameTFRef = useRef(null);
    const firstRenderRef = useRef(true);
    const defaultFocusRef = useRef(null);

    // Component state vars
    const [isLoading, setIsLoading] = useState(false);
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);    
    const [showPWModal, setShowPWModal] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(true);    
    const [viewData, setViewData] = useState({
        id: "",
        org_id: '',
        product_id: '',
        seats: {},
        num_seats: null,
        used_seats: null
    })
    const [licensedUsers, setLicensedUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [product, setProduct] = useState({});
    const [checkedStates, setCheckedStates] = useState([]);
    const [listUsers, setListUsers] = useState([]);

    const findLicensedUsers = users => {
        let retArr = [];
        for (let i = 0; i < users.length; i++) {
            const user = users[i];
            let res = viewData.seats.users.filter(usr => usr === user.id)
            if (res.length !== 0){
                retArr.push(user);    
            }
        }
        return retArr;
    }
    

    // Data fetching methods
    /**
     *  Fetch call to get license from the id
     *  passed in from the URL
     * 
     *  calls 
     *      @function setViewData()
     *  to set License data upon successful return
     *  return @object
     *      id,
     *      num_seats,
     *      org_id,
     *      product_id
     *      seats,
     *          total,
     *          used,
     *          users
     *      used_seats          
     **/
    const fetchLicenseForId = (_licenseId) => {
        let fd = createFormDataObj({
            action: 'get_license',
            session,
            uid: userId,
            id: _licenseId
        });

        apiDataFetch(window.API_URL,{method:"POST",body:fd}).then(result => {
            console.log('Organization detail view');
            console.log(result);
            if (result.success === true){
                let usr = result.data[0];
                setViewData({...viewData,...usr});
            } 
        }).catch(error => {
            console.error('Error:',error);
        })
    }
    /**
     *  Fetch call to users of an organization
     *  using org_id from viewData state var
     * 
     *  calls 
     *      @function setUsers()
     *  to set License data upon successful return
     *  *  request return data @object
     *      id,
     *      name,
     *      email,
     *      isAdmin,
     *      org_id,
     *      org_name
     **/ 
    const fetchUsersForOrganization = (_orgId) => {
        let fd = createFormDataObj({
            action: 'list_users',
            session,
            uid: userId,
            org_id: _orgId
        });
        apiDataFetch(window.API_URL,{method:'POST',body:fd}).then(result => {
            console.table(`API RESPONSE: Success=${result.success}, Message=${result.message}`);                   
            if(result.success === true){
                let licensedUsers = findLicensedUsers(result.data);
                setUsers(result.data);
                setLicensedUsers(findLicensedUsers(result.data));
                setListUsers(findLicensedUsers(result.data));
                let tmpArr  = new Array(result.data.length).fill(false);                
                for (let i = 0; i < viewData.seats.users.length; i++) {
                    const usr = viewData.seats.users[i];
                    for (let k = 0; k < result.data.length; k++) {
                        const user = result.data[k];
                        if (user.id === usr) {
                            tmpArr[k] = true;
                        }
                    }
                }
                setCheckedStates([...tmpArr])
                return;
            }
        });
    }
    /**
     *  Fetch call that returns the product
     *  the license is tied to
     * 
     *  calls 
     *      @function setProduct()
     *  to set License data upon successful return
     *  *  request return data @object
     *      uid,
     *      name,
     *      srp,
     *      description
     **/ 
    const fetchProductForLicense = (_prodId) => {
        let fd = createFormDataObj({
            action: 'get_product',
            session,
            uid: userId,
            id: _prodId
        });
        
        apiDataFetch(window.API_URL,{method:'POST',body:fd}).then(result => {
            console.table(`API RESPONSE: Success=${result.success}, Message=${result.message}`);                   
            if(result.success === true){
                setProduct(result.data);
                return;
            }
        }).catch(error => {
            console.error('Error:',error);
        })
    }

    
    

    // get license data onload
    useEffect(() => {
        fetchLicenseForId(urlParams.id)        
    },[urlParams.id]);
    // get user data onload; on completion of license fetch
    useEffect(() => {
        if (firstRenderRef.current === true && viewData.id === "") return;
        if (firstRenderRef.current === false) return;
        fetchUsersForOrganization(viewData.org_id);
        firstRenderRef.current = false;
    },[viewData])
    // get product data onload; on completion of user fetch
    useEffect(() => {
        if (users.length === 0) return;
        if (users[0].id === "") return;
        fetchProductForLicense(viewData.product_id);
    },[users])
    
    
    // toggles the dropdown menu when clicking the gear icon
    const toggleDropdownMenu = (e) => {
        setIsDropdownVisible(isDropdownVisible ? false:true);
        navIconRef.current.classList.toggle('active');
    }
    // handles the dropdown menus selection event
    const dropdownAction = (e) => {
        switch (e.view) {
            case "edit":
                setIsReadOnly(!isReadOnly);
                defaultFocusRef.current.focus();
                setListUsers(users);
            default:
                break;
        }
    }

    const endEditEvent = (e) => {
        setIsReadOnly(true);
        setListUsers(licensedUsers);
        

        let fd = createFormDataObj({
            action: "update_license",
            session,
            uid: userId,
            update_id: viewData.id,
            seats: parseInt(viewData.seats.total)
        });
        apiDataFetch(window.API_URL,{method:"POST",body:fd}).then(result => {
            if (result.success === true){
                setIsReadOnly(true);
                fetchLicenseForId(viewData.id);
            }    
        }).catch(error => {
            console.error('Error:',error);
        })
    }
    const cancelEditEvent = (e) => {
        setIsReadOnly(true);
        setListUsers(licensedUsers);
    }

    
   

    const checkboxClickEvent = (e,eleIndex) => {
        
        let checkedStatus = checkedStates[eleIndex];        
        let checkboxVal = e.target.dataset.value;

        let li = e.target.closest('li');
        let ele = li.querySelector('.license__user__item__loading');
        let loadingEle = li.querySelector('.license__user__item__loading');
        let bannerEle = li.querySelector('.license__user__item__banner');
        li.classList.toggle('is-loading');
        
        setDisabledBtn(true);
        
        
        let updatedCheckState = checkedStates.map((check,index) => {
            return index === eleIndex ? !check : check;
        })
        
        
        if (checkedStatus){
            setCheckedStates(updatedCheckState);
            let fd = createFormDataObj({
                action: 'delete_license_seat',
                session,
                uid: userId,
                delete_id: parseInt(checkboxVal)
            });
            apiDataFetch(window.API_URL,{method:"POST",body:fd}).then(result => {
                if (result.success === true){
                    const intv = setTimeout(() => {
                        li.classList.toggle('is-loading');
                        bannerEle.firstElementChild.innerText = "License Removed!"
                        bannerEle.classList.toggle('success');
                        bannerEle.classList.toggle('is-visible');
                        const intv2 = setTimeout(() => {
                            bannerEle.classList.toggle('is-visible');
                            setDisabledBtn(false);
                        },3000);
                        clearTimeout(intv);
                    },1000)
                    return;
                }
            });
            
            let res = viewData.seats.users.filter(user => parseInt(user) === parseInt(checkboxVal));
            debugger;
            let tmpA = [];
            if (res.length !== 0){
                res.forEach(user => {
                    tmpA.push(user.id);
                });
            }
            setViewData({...viewData,seats:{...viewData.seats,users:tmpA}});
            let filtered = licensedUsers.filter(fUser => parseInt(fUser.id) !== parseInt(checkboxVal));
            setLicensedUsers(filtered);
            fetchLicenseForId(viewData.id);
            return;
        }
        
        if (viewData.num_seats === viewData.used_seats){
            li.classList.toggle('is-loading');
            li.classList.toggle('is-error');
            bannerEle.firstElementChild.innerText = "ERROR: No availble licenses remain!"
            bannerEle.classList.toggle('is-error');
            bannerEle.classList.toggle('is-visible');
            const intv = setTimeout(() => {
                bannerEle.classList.toggle('is-error');
                bannerEle.classList.toggle('is-visible');
                setDisabledBtn(false);
                clearTimeout(intv);
            },1000);
            return;
        }
        
        setCheckedStates(updatedCheckState);
        
        let fd = createFormDataObj({
            action: 'create_license_seat',
            session,
            uid: userId,
            license_id: viewData.id,
            user_id: parseInt(checkboxVal)
        });
        apiDataFetch(window.API_URL,{method:"POST",body:fd}).then(result => {
            if (result.success === true){
                fetchLicenseForId(viewData.id);
                const intv = setTimeout(() => {
                    li.classList.toggle('is-loading');
                    bannerEle.firstElementChild.innerText = "License Added!"
                    bannerEle.classList.toggle('is-success');
                    bannerEle.classList.toggle('is-visible');
                    const intv2 = setTimeout(() => {
                        bannerEle.classList.toggle('is-success');
                        bannerEle.classList.toggle('is-visible');
                        setDisabledBtn(false);
                        clearTimeout(intv2);                        
                    },1000);
                    clearTimeout(intv);
                },1000)
                return;
            }
        }).catch(error => {
            console.error('Error:',error);
        })
        
        let filtered = users.filter(fUser => parseInt(fUser.id) === parseInt(checkboxVal));
        let tmpA = [...viewData.seats.users,...[parseInt(checkboxVal)]];
        setViewData({...viewData,seats:{...viewData.seats,users:tmpA}});
        setLicensedUsers([...licensedUsers,...filtered]);
    }

    const totalSeatsInputChangeEvent = (e) => {
        let tmp = {...viewData,seats:{...viewData.seats,total:e.target.value}};
        setViewData(tmp);
    }

    console.log('UserDetailView.js checkedStates: '+JSON.stringify(checkedStates));
    return (
        <div className="license__detail-view" id="license-detail-view">
            <nav>            
                <div className='nav__row'>
                    <div ref={navIconRef} className='nav__icon' onClick={toggleDropdownMenu}>
                        <span><Gear size={35} /></span>
                        {isDropdownVisible &&  (
                            <DropDownSelect direction={"right"} viewAction={setIsDropdownVisible} content={dropdownContent} selectAction={dropdownAction} />
                        )}
                    </div>
                </div>
            </nav>
            <div className='title'>
                <h2>Product ID Licensed : {product.name}</h2>
            </div>
            <div className='form'>
                <form>
                    <div className={"row p-3"} style={{height:'100%'}} >
                        {/* Left side text input column */}
                        <div className={'col-6'}>
                            <div className={'form-floating mb-3'}>
                                <input
                                    ref={nameTFRef}
                                    type="text"
                                    className="form-control"
                                    id="license-id"
                                    value={viewData.id}
                                    placeholder="License ID"
                                    readOnly={true}
                                />
                                <label htmlFor="license-id" aria-label='License ID field'>License ID</label>
                            </div> 
                            <div className={'form-floating mb-3'}>
                                <input
                                    ref={defaultFocusRef}
                                    type="text"
                                    className="form-control"
                                    id="total-seats"
                                    value={viewData.seats.total}
                                    onChange={totalSeatsInputChangeEvent}
                                    placeholder="Total seats"
                                    readOnly={isReadOnly}                                    
                                />
                                <label htmlFor="total-seats" aria-label='Total seats field'>Total seats</label>
                            </div>
                            <div className={'form-floating mb-3'}>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="avail-seats"
                                    value={viewData.seats.used ? (viewData.seats.total - viewData.seats.used) : viewData.seats.total}
                                    placeholder="Available seats"
                                    readOnly={true}
                                />
                                <label htmlFor="avail-seats" aria-label='Available seats field'>Available seats</label>
                            </div>
                        </div>
                        
                        {/* Right side agent assignment column */}
                        <div className='col-6 px-3' >
                            
                            <div className='license__users__assign-view'>
                                
                                {!isReadOnly && <div className='heading'>
                                    <h2>Assign license to user</h2>
                                </div>}
                                <div className='body'>
                                    <div>
                                        <h4>{!isReadOnly ?  "Available users" : "Licensed users"}</h4>
                                    </div>
                                    <ul>
                                        
                                        {listUsers.map((user,index) => {
                                            let tmp = listUsers;  
                                            return (
                                                <li className='license__user__item'>
                                                    
                                                    <div className='license__user__item__banner'>
                                                        <span></span>
                                                    </div>
                                                    <div className={`license__user__item__loading ${isLoading ? "is-loading" : ""}`}>
                                                        <img src={loader} />
                                                    </div>
                                                    <div className={`license__user__item__cb-wrapper ${!isReadOnly ? "" : "is-hidden"}`}>
                                                    
                                                        <label class="container" htmlFor={`custom-checkbox-${index}`} title="Add license">
                                                            <input
                                                                type="checkbox"
                                                                tabindex="0"
                                                                id={`custom-checkbox-${index}`}
                                                                checked={checkedStates[index]}
                                                                onChange={(e) => checkboxClickEvent(e,index)}
                                                                data-value={user.id}
                                                            />
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </div>
                                                    <div className='license__user__item__details-wrapper'>
                                                        <span>
                                                            {user.name}
                                                        </span>
                                                        <span>
                                                            {user.email}
                                                        </span>
                                                    </div>
                                                    {/* <span>{agent.store}</span> */}
                                                    {/* <span>{agent.location}</span> */}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                                
                            </div>
                        </div>                        
                    </div>
                </form>
           </div>

           {!isReadOnly && <div className='btm__nav'>
                <div className="row">
                    <div className='col-2'></div>
                    <div className="col-4">
                        <div className='add__user__btn__container'>
                            <WEButton btnColor='#DA2C38' disabled={disabledBtn} onClick={cancelEditEvent} type={'outline'}>
                                <div className='btn-content cancel'>
                                    <span><XCircle size={18} /></span>
                                    <span>Cancel</span>
                                </div>
                            </WEButton>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className='add__user__btn__container'>
                            <WEButton btnColor='#00a651' disabled={disabledBtn} onClick={endEditEvent} type={'outline'}>
                                <div className='btn-content save'>
                                    <span><Save size={18} /></span>
                                    <span>Save</span>
                                </div>
                            </WEButton>
                        </div>                            
                    </div>
                    <div className='col-2'></div>
                </div>
           </div>}

        </div>
    ); 
}
export default LicenseDetailView;
