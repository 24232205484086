import React, { useState,useContext,useEffect,useRef } from 'react';
import { useParams,useNavigate } from "react-router-dom";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { PlusSquare } from 'react-bootstrap-icons';
import icons from '../../../../AppIcons';
import { useLocalStorage } from '../../../../useLocalStorage';
import { capitalizeFirstLetter,createFormDataObj } from '../../../../Utilities';
import './OrganizationDetailView.css';
import GLDSFormModal from '../../../GLDSFormModal/GLDSFormModal';
import AddUserForm from '../../Forms/AddUserForm/AddUserForm';
import AddLicenseForm from '../../Forms/AddLicenseForm/AddLicenseForm';
import AddProductForm from '../../Forms/AddProductForm/AddProductForm';



const OrganizationDetailView = () => {
    const [session, setSession] = useLocalStorage('session');
    const [userId, setUserId] = useLocalStorage('uid');
    const urlParams = useParams();
    const navigate = useNavigate();
    const [viewData, setViewData] = useState({
        id: "",
        name: '',
        address: "",
        city: '',
        state: '',
        zip: "",
        contact: "",
        phone: '',
        email: ''
    })
    const orgDetailViewRef = useRef(null);
    const [gridApi, setGridApi] = useState();
    const [userGridApi, setUserGridApi] = useState();
    const [licenseGridApi, setLicenseGridApi] = useState();
    const [gridColumnApi, setGridColumnApi] = useState();
    const [userGridColumnApi, setUserGridColumnApi] = useState();
    const [licenseGridColumnApi, setLicenseGridColumnApi] = useState();
    const [colDefs, setColDefs] = useState([]);
    const [userColDefs, setUserColDefs] = useState([]);
    const [licenseColDefs, setLicenseColDefs] = useState([]);
    const [userRowData, setUserRowData] = useState([]);
    const [agentRowData, setAgentRowData] = useState([]);
    const [licenseRowData, setLicenseRowData] = useState([]);
    let firstClick = false;
    

    const [formModalShowing, setFormModalShowing] = useState(false);
    const [modal, setModal] = useState();

    useEffect(() => {
        let fd = createFormDataObj({
            action: 'get_organization',
            session,
            uid: userId,
            org_id: urlParams.org_id
        });
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => {  
            console.log('Organization detail view');
            console.log(result);
            if (result.success === true){
                setViewData(result.data[0]);                
            }            
        })
        .catch(error => {
            console.error('Error:',error);
        })
    },[urlParams.org_id]);

    useEffect(() => {
        if (viewData.id === "") return;                    
        getOrgAgents();
        getOrgUsers();
        getOrgLicense();
    },[viewData])

    const getOrgAgents = () => {
        let fd = createFormDataObj({
            action: 'list_agents',
            session,
            uid: userId,
            org_id: viewData.id
        });
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => { 
            console.table(`API RESPONSE: Success=${result.success}, Message=${result.message}`);             
            if(result.success === true){
                console.log(result.message);
                console.table(result);                   
                setAgentRowData([...result.data]);
                createColumnDefs(result.data[0]);
                return;
            }
        })
        .catch(error => {
            console.error('Error:',error);
        })
    }
    const getOrgUsers = () => {
        let fd = createFormDataObj({
            action: 'list_users',
            session,
            uid: userId,
            org_id: viewData.id
        });
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => { 
            console.table(`API RESPONSE: Success=${result.success}, Message=${result.message}`);
            
            if(result.success === true){
                console.log(result.message);
                console.table(result);
                setUserRowData([...result.data]);
                createUserColumnDefs(result.data[0]);
                return;
            }
        })
        .catch(error => {
            console.error('Error:',error);
        })
    }
    const getOrgLicense = () => {
        let fd = createFormDataObj({
            action: 'list_licenses',
            session,
            uid: userId,
            org_id: viewData.id
        });
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => { 
            console.table(`API RESPONSE: Success=${result.success}, Message=${result.message}`);    
            if(result.success === true){
                console.log(result.message);
                console.table(result);
                setLicenseRowData([...result.data]);
                createLicenseColumnDefs(result.data[0]);
                return;
            }
        })
        .catch(error => {
            console.error('Error:',error);
        })
    }

    
    const deleteDBRecord = (recordId) => {
        let fd = createFormDataObj({
            action: 'delete_agent',
            session,
            uid: userId,
            delete_id: recordId
        });
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => {
            if(result.success === true){
                console.log(result.message);
                return;
            }
        })
        .catch(error => {
            console.error('Error:',error);
        })
    }
    const deleteUserDBRecord = (recordId) => {
        let fd = createFormDataObj({
            action: 'delete_user',
            session,
            uid: userId,
            delete_id: recordId
        });        
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => {
            if(result.success === true){
                console.log(result.message);
                return;
            }
        })
        .catch(error => {
            console.error('Error:',error);
        })
    }
    const deleteLicenseDBRecord = (recordId) => {
        let fd = createFormDataObj({
            action: 'delete_license',
            session,
            uid: userId,
            delete_id: recordId
        });        
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => {
            if(result.success === true){
                console.log(result.message);
                return;
            }
        })
        .catch(error => {
            console.error('Error:',error);
        })
    }
    const updateUserDBRecord = (recordObj) => {
        let fd = createFormDataObj({
            action: 'update_user',
            session,
            uid: userId,
        });
        for (const key in recordObj) {
            if (Object.hasOwnProperty.call(recordObj, key)) {
                let record = recordObj[key];
                if (key.toLocaleLowerCase() === 'id') {
                    record = parseInt(record);
                }
                console.log(`Key: ${key} Value: ${record}`);
                fd.append(key,record);
            }
        }
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => {
            console.log(`Request response: ${result.message}`);
            if(result.success === true){
                console.log(result.message);
                return;
            }
        })
        .catch(error => {
            console.error('Error:',error);
        })
    }
    const updateLicenseDBRecord = (recordObj) => {
        let fd = createFormDataObj({
            action: 'update_license',
            session,
            uid: userId,
        });
        for (const key in recordObj) {
            if (Object.hasOwnProperty.call(recordObj, key)) {
                let record = recordObj[key];
                if (key.toLocaleLowerCase() === 'id') {
                    record = parseInt(record);
                }
                console.log(`Key: ${key} Value: ${record}`);
                fd.append(key,record);
            }
        }
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => {
            console.log(`Request response: ${result.message}`);
            if(result.success === true){
                console.log(result.message);
                return;
            }
        })
        .catch(error => {
            console.error('Error:',error);
        })
    }

    const manageCellRenderer = params => {
        let ele = document.createElement("div");
        ele.classList.add('manage_btn_cell');
        let editingCells = params.api.getEditingCells();

        let isCurrentRowEditing = editingCells.some((cell) => {
            return cell.rowIndex === params.node.rowIndex;
        });

        if (isCurrentRowEditing) {
           ele.innerHTML = `
                <button class="row_manage_btn" data-action="update">${icons.save}</button>
                <button class="row_manage_btn" data-action="cancel">${icons.cancel}</button>
            `;
        } else {
            ele.innerHTML = `
                <button class="row_manage_btn" data-action="view">${icons.view}</button>
                <button class="row_manage_btn" data-action="edit">${icons.edit}</button>
                <button class="row_manage_btn" data-action="delete">${icons.delete}</button>
            `;
        }
        return ele;
    }
    const manageDeleteCellRenderer = params => {
        let ele = document.createElement("div");
        ele.classList.add('manage_btn_cell');
        ele.innerHTML = `
            <button class="row_manage_btn" title="Copy agent" data-action="copy">${icons.clipboardCopy}</button>
            <button class="row_manage_btn" title="Delete agent" data-action="delete">${icons.delete}</button>
        `;
        return ele;
    }
    const manageCell = {
        headerName: "Manage",
        cellRenderer: manageCellRenderer,
        editable: false,
        colId: "manage",
        maxWidth: 150,
        sortable: false,
        resizable: false,
        filter: false,
    }
    const manageDeleteCell = {
        headerName: "Manage",
        cellRenderer: manageDeleteCellRenderer,
        editable: false,
        colId: "manage",
        maxWidth: 125,
        sortable: false,
        resizable: false,
        filter: false,
    }

    const createColumnDefs = (rowObj) => {
        let colNames = Object.keys(rowObj);
        let defs = colNames.map(col => {
            let isVisible = false;
            if (col.toLocaleLowerCase() === "org_id") {
                isVisible = true;
            }
            let header = (col.toLocaleLowerCase() === "org_id") ? "Org Id" : col.toLocaleLowerCase() === "isadmin" ? "Admin" : col.toLocaleLowerCase() === "org_name" ? "Organization" : capitalizeFirstLetter(col);
            let retObj = {
                headerName: header,
                field: col,
                hide: isVisible
            }
            if ((col.toLocaleLowerCase() === "org_id") || (col.toLocaleLowerCase() === "id") || (col.toLocaleLowerCase() === "isadmin")) {
                retObj['maxWidth'] = 125;
            }
            return retObj;
        });
        defs.push(manageDeleteCell);
        setColDefs(defs);
    }
    const createUserColumnDefs = (rowObj) => {
        let colNames = Object.keys(rowObj);
        
        let defs = colNames.map(col => {
            let isVisible = false;
            if (col.toLocaleLowerCase() === "org_id") {
                isVisible = true;
            }
            let editor = col.toLowerCase().includes('isadmin') ? cellEditorSelector : "";
            let header = (col.toLocaleLowerCase() === "org_id") ? "Org Id" : col.toLocaleLowerCase() === "isadmin" ? "Admin" : col.toLocaleLowerCase() === "org_name" ? "Organization" : capitalizeFirstLetter(col);
            let retObj = {
                headerName: header,
                field: col,
                editable: col.toLowerCase().includes('id') ? false : true,
                cellEditorSelector: editor,
                hide: isVisible
            }
            if ((col.toLocaleLowerCase() === "org_id") || (col.toLocaleLowerCase() === "id") || (col.toLocaleLowerCase() === "isadmin")) {
                retObj['maxWidth'] = 125;
            }

            return retObj;
        });
        defs.push(manageCell);
        setUserColDefs(defs);
    }
    const createLicenseColumnDefs = (rowObj) => {
        let colNames = Object.keys(rowObj);
        let defs = colNames.map(col => {
            let isVisible = false;
            if ((col.toLocaleLowerCase() === "org_id") || (col.toLocaleLowerCase() === "product")){
                isVisible = true;
            }            
                        
            let header = (col.toLocaleLowerCase() === "org_name") ? "Organization" : col.toLocaleLowerCase() === "product_name" ? "Product" : capitalizeFirstLetter(col);
            let retObj = {
                headerName: header,
                field: col,
                hide: isVisible,
            }
            if ((col.toLocaleLowerCase() === "org_id") || (col.toLocaleLowerCase() === "id") || (col.toLocaleLowerCase() === "isadmin")) {
                retObj['maxWidth'] = 125;
            }
            return retObj;
        });
        defs.push(manageCell);
        setLicenseColDefs(defs);
    }
    
    const onUserRowClicked = params => {
        let intv = "";
        if (firstClick) {
            navigate(`/admin/users/${params.data.id}`);    
            clearTimeout(intv);
        }
        intv = setTimeout(() => {
            firstClick = false;
        },300);
        firstClick = true;
    }
    const onLicenseRowClicked = params => {
        let intv = "";
        if (firstClick) {
            navigate(`/admin/licenses/${params.data.id}`);    
            clearTimeout(intv);
        }
        intv = setTimeout(() => {
            firstClick = false;
        },300);
        firstClick = true;
    }
    const onAgentRowClicked = params => {
        let intv = "";
        if (firstClick) {
            navigate(`/admin/agents/${params.data.agent}`);    
            clearTimeout(intv);
        }
        intv = setTimeout(() => {
            firstClick = false;
        },300);
        firstClick = true;
    }

    const onCellClicked = params => {
        let target = params.event.target.closest('button');
        
        
        if (params.column.colId === "manage" && target.dataset.action) {
            
            let action = target.dataset.action;
        
            if (action === "edit") {
              params.api.startEditingCell({
                rowIndex: params.node.rowIndex,
                colKey: params.columnApi.getDisplayedCenterColumns()[1].colId
              });
            }

            if (action === "copy") {
                
                let editingCell = params.columnApi.getDisplayedCenterColumns()[0].colId;
                let agent = params.node.data.agent;
                navigator.clipboard.writeText(agent);
                let cell = target.closest('.ag-row');
                let ele = document.createElement('div');
                ele.classList.add('copied__alert');
                ele.innerText = "Agent copied!";
                cell.appendChild(ele);
                let intv = setTimeout(() => {
                    ele.classList.add('show__alert');
                    clearTimeout(intv);   
                }, 100);
                // let intv2 = setTimeout(() => {
                //     ele.classList.remove('show__alert');
                //     clearTimeout(intv2);
                // }, 1500);
                // let intv3 = setTimeout(() => {
                //     ele.remove();
                //     clearTimeout(intv3);
                // }, 1800);
              }

            if (action === "delete") {
                let result = window.confirm('Are you sure you want to delete this record?');
                if (result) {
                    deleteDBRecord(params.node.data.id);
                    params.api.applyTransaction({
                        remove: [params.node.data]
                    });
                }
            }
        }
    }

    const onUserCellClicked = params => {
        let target = params.event.target.closest('button');
        if (params.column.colId === "manage" && target.dataset.action) {
            
            let action = target.dataset.action;
        
            if (action === "edit") {
              params.api.startEditingCell({
                rowIndex: params.node.rowIndex,
                colKey: params.columnApi.getDisplayedCenterColumns()[2].colId
              });
            }

            if (action === "copy") {
                let editingCell = params.columnApi.getDisplayedCenterColumns()[0].colId;
                let agent = params.node.data.agent;
                navigator.clipboard.writeText(agent);
                let cell = target.parentElement.parentElement.parentElement.parentElement;
                let ele = document.createElement('div');
                ele.classList.add('copied__alert');
                ele.innerText = "Copied!";
                cell.appendChild(ele);
                let intv = setTimeout(() => {
                    ele.classList.add('show__alert');
                    clearTimeout(intv);   
                }, 100);
                let intv2 = setTimeout(() => {
                    ele.classList.remove('show__alert');
                    clearTimeout(intv2);
                }, 1500);
                let intv3 = setTimeout(() => {
                    ele.remove();
                    clearTimeout(intv3);
                }, 1800);
              }

            if (action === "update") {
            
            params.api.stopEditing(false);
            }


            if (action === "view") {
                setModal(modalForType('user',params.node.data.name,params.node.data,true));
                setFormModalShowing(true);
            }
            if (action === "delete") {
                let result = window.confirm('Are you sure you want to delete this record?');
                if (result) {
                    deleteUserDBRecord(params.node.data.id);
                    params.api.applyTransaction({
                        remove: [params.node.data]
                    });
                }
            }
            if (action === "cancel") {
                params.api.stopEditing(true);
            }
        }
    }

    const onLicenseCellClicked = params => {
        let target = params.event.target.closest('button');
        
        
        if (params.column.colId === "manage" && target.dataset.action) {
            
            let action = target.dataset.action;
        
            if (action === "edit") {
              params.api.startEditingCell({
                rowIndex: params.node.rowIndex,
                colKey: params.columnApi.getDisplayedCenterColumns()[1].colId
              });
            }

            if (action === "copy") {
                
                let editingCell = params.columnApi.getDisplayedCenterColumns()[0].colId;
                let agent = params.node.data.agent;
                navigator.clipboard.writeText(agent);
                let cell = target.closest('.ag-row');
                let ele = document.createElement('div');
                ele.classList.add('copied__alert');
                ele.innerText = "Agent copied!";
                cell.appendChild(ele);
                let intv = setTimeout(() => {
                    ele.classList.add('show__alert');
                    clearTimeout(intv);   
                }, 100);
                // let intv2 = setTimeout(() => {
                //     ele.classList.remove('show__alert');
                //     clearTimeout(intv2);
                // }, 1500);
                // let intv3 = setTimeout(() => {
                //     ele.remove();
                //     clearTimeout(intv3);
                // }, 1800);
              }

            if (action === "delete") {
                let result = window.confirm('Are you sure you want to delete this record?');
                if (result) {
                    deleteLicenseDBRecord(params.node.data.id);
                    params.api.applyTransaction({
                        remove: [params.node.data]
                    });
                }
            }
        }
    }

    function cellEditorSelector(params) {
        return {
            component: 'agSelectCellEditor',
            params: {
              values: ['Y', 'N'],
            },
          };
    }

    const onRowEditingStarted = params => {
        
        params.api.refreshCells({
          columns: ["manage"],
          rowNodes: [params.node],
          force: true
        });
    }
    const onRowEditingStopped = params => {
        params.api.refreshCells({
            columns: ["manage"],
            rowNodes: [params.node],
            force: true
        });
    }
    const onRowValueChanged = event => {
        updateUserDBRecord(event.node.data);
        event.api.refreshCells({
            columns: ["manage"],
            rowNodes: [event.node],
            force: true
        });
    }
    const onLicenseRowValueChanged = event => {
        updateLicenseDBRecord(event.node.data);
        event.api.refreshCells({
            columns: ["manage"],
            rowNodes: [event.node],
            force: true
        });
    }

    const onGridReady = params => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        setColDefs([]);
        setAgentRowData([]);
        
    }
    const onUserGridReady = params => {
        setUserGridApi(params.api);
        setUserGridColumnApi(params.columnApi);
        setUserColDefs([]);
        setUserRowData([]);
        
    }
    const onLicenseGridReady = params => {
        setLicenseGridApi(params.api);
        setLicenseGridColumnApi(params.columnApi);
        setLicenseColDefs([]);
        setLicenseRowData([]);
        
    }
    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1
    }
    const userDefaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1
    }
    const licenseDefaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1
    }

    const addAgentBtnEvent = e => {
        console.log(e);
        console.log('Add agent button clicked event');
        e.preventDefault();
        let fd = createFormDataObj({
            action: 'create_agent',
            session,
            uid: userId,
            org_id: viewData.id
        });
        // const fd = new FormData();
        // fd.append('action','create_agent');
        // fd.append("uid",state.uid);
        // fd.append("session",state.sid);
        // fd.append('org_id',viewData.id);
    
        
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => { 
            console.table(`API RESPONSE: Success=${result.success}, Message=${result.message}`);                   
            if(result.success === true){
                console.log(result.message);
                console.log(result);
                createColumnDefs(result.data);
                setAgentRowData([...agentRowData,...[result.data]]);
                return;
            }
        })
        .catch(error => {
            console.error('Error:',error);
        })
    }
    const addUserBtnEvent = e => {
        e.preventDefault();
        console.log(e);
        console.log('Add agent button clicked event');
        orgDetailViewRef.current.style.overflowY = 'hidden';
        setModal(modalForType('user','Create User',{org_id:viewData.id}));
        setFormModalShowing(true);
    }
    const addLicenseBtnEvent = e => {
        e.preventDefault();
        console.log(e);
        console.log('Add agent button clicked event');
        orgDetailViewRef.current.style.overflowY = 'hidden';
        setModal(modalForType('product','Add a Product',{org_id:viewData.id}));
        setFormModalShowing(true);
    }

    const modalAction = (formData,type) => {
        if(!formData) { setFormModalShowing(false); return;}
        debugger;
        let fd = createFormDataObj({...formData,...{action:type,uid:userId,session:session}});
        console.log(`Add ${type} modal action`);
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => {
            if(result.success === true){
                console.log(result.message);
                if (Object.keys(result.data).find(element => element === "product_id")){
                    console.log('License modal action response');
                    console.log(result.data);
                    let testArr = [...licenseRowData,...[result.data]];
                    setLicenseRowData([...testArr]);
                    createLicenseColumnDefs(testArr[0]);
                    return;
                }
                setUserRowData([...userRowData,...[result.data]]);
                return;
            }
            console.log(result.message);
        })
        .catch(error => {
            console.error('Error:',error);
        })
        setFormModalShowing(false);
        orgDetailViewRef.current.style.overflowY = 'scroll';
    }
    const modalForType = (type,title,data,readOnly) => {
        switch (type.toLowerCase()) {
            case 'user':
                return (
                    <GLDSFormModal
                    top={orgDetailViewRef.current.scrollTop}
                    title={title}
                    form={
                        <AddUserForm
                            readonly={readOnly}
                            formData={data ? data : null}
                            submitAction={modalAction} 
                        />
                    }
                />
                )
            case 'product':
                return (
                    <GLDSFormModal
                    top={orgDetailViewRef.current.scrollTop}
                    title={title}
                    form={                    
                        <AddProductForm
                            readonly={readOnly}
                            formData={data ? data : null}
                            submitAction={modalAction} 
                        />
                    }
                />
                )
            default:
                break;
        }
    }
    return (        
        <div ref={orgDetailViewRef} className="organization__detail__view">
            {formModalShowing && (modal)}
            <div className='add__orginaztion__title'>
                <h2>{viewData.name}</h2>
            </div>
            <div className='add__organization__form'>
                <form>
                    <div className="row">
                        <div className='col-1'></div>
                        <div className="col-5">
                            <fieldset>
                                <legend>Billing Information</legend>
                                <div className={'form-floating mb-3'}>
                                    <input type="text" className="form-control" id="org-address" value={viewData.address} placeholder="Address" readOnly/>
                                    <label htmlFor="org-address" aria-label='Billing Address'>Address</label>
                                </div>
                                <div className={'form-floating mb-3'}>
                                    <input type="text" className="form-control" id="org-city" value={viewData.city} placeholder="City" readOnly/>
                                    <label htmlFor="org-city" aria-label='Billing City'>City</label>
                                </div>
                                <div className={'form-floating mb-3'}>
                                    <input type="text" className="form-control" id="org-state" value={viewData.state} placeholder="State" readOnly/>                                
                                    <label htmlFor="org-state" aria-label='billing State'>State</label>
                                </div>
                                <div className={'form-floating mb-3'}>
                                    <input type="text" className="form-control" id="org-zipcode" value={viewData.zip} placeholder="Zipcode" readOnly/>
                                    <label htmlFor="org-zipcode" aria-label='Billing Zipcode'>Zipcode</label>
                                </div>
                            </fieldset>
                        </div>
                        <div className="col-5">
                            <fieldset>
                                <legend>Contact Information</legend>
                                <div className={'form-floating mb-3'}>
                                    <input type="text" className="form-control" id="contact-name" value={viewData.contact} placeholder="Name" readOnly/>
                                    <label htmlFor="contact-name" aria-label='Contact Name'>Name</label>
                                </div>
                                <div className={'form-floating mb-3'}>
                                    <input type="text" className="form-control" id="contact-name" value={viewData.phone} placeholder="Phone" readOnly/>
                                    <label htmlFor="contact-name" aria-label='Contact Phone'>Phone</label>
                                </div>
                                <div className={'form-floating mb-3'}>
                                    <input type="email" className="form-control" id="contact-email" value={viewData.email} placeholder="Email" readOnly/>
                                    <label htmlFor="contact-email" aria-label='Contact Email'>Email</label>
                                </div>
                                <div className={'form-floating mb-3 hidden'}>
                                    <input type="text" className="form-control" id="contact-email" placeholder="Email" readOnly/>
                                    <label htmlFor="contact-email" aria-label='Contact Email'>Email</label>
                                </div>
                            </fieldset>
                        </div>
                        <div className='col-1'></div>
                    </div>
                </form>
            </div>
            <div className='org__detail__add__agent__div'>
                <div className='org__detail__add__agent__action__row'>
                    <div className={'org__detail__add__agent__title'}>Add a Product:</div>
                    <PlusSquare size={30} onClick={addLicenseBtnEvent}/>
                </div>
                <div className='org__detail__add__agent__grid__row'>
                    <div className="ag-theme-alpine" style={{height: '100%', width: '100%'}}>
                        <AgGridReact
                            onRowEditingStopped={onRowEditingStopped}
                            onRowEditingStarted={onRowEditingStarted}
                            onRowValueChanged={onLicenseRowValueChanged}
                            onCellClicked={onLicenseCellClicked}
                            onRowClicked={onLicenseRowClicked}
                            stopEditingWhenCellsLoseFocus={true}
                            editType="fullRow"
                            animateRows={true}
                            suppressClickEdit={true}
                            columnDefs={licenseColDefs}
                            defaultColDef={licenseDefaultColDef}
                            rowSelection={'single'}
                            onGridReady={onLicenseGridReady}
                            rowData={licenseRowData}
                        />
                    </div>
                </div>
            </div>
            <div className='org__detail__add__agent__div'>
                <div className='org__detail__add__agent__action__row'>
                    <div className={'org__detail__add__agent__title'}>Create a store agent:</div>
                    <PlusSquare size={30} onClick={addAgentBtnEvent}/>
                </div>
                <div className='org__detail__add__agent__grid__row'>
                    <div className="ag-theme-alpine" style={{height: '100%', width: '100%'}}>
                        <AgGridReact
                            onCellClicked={onCellClicked}
                            onRowClicked={onAgentRowClicked}
                            animateRows={true}
                            suppressClickEdit={true}                               
                            rowSelection={'single'}
                            onGridReady={onGridReady}
                            columnDefs={colDefs}
                            defaultColDef={defaultColDef}
                            rowData={agentRowData}
                        />
                    </div>
                </div>
            </div>
            <div className='org__detail__add__user__div'>
                <div className='org__detail__add__user__action__row'>
                    <div className={'org__detail__add__user__title'}>Add store user:</div>
                    <PlusSquare size={30} onClick={addUserBtnEvent}/>
                </div>
                <div className='org__detail__add__user__grid__row'>
                    <div className="ag-theme-alpine" style={{height: '100%', width: '100%'}}>
                        <AgGridReact
                            onRowEditingStopped={onRowEditingStopped}
                            onRowEditingStarted={onRowEditingStarted}
                            onRowValueChanged={onRowValueChanged}
                            onCellClicked={onUserCellClicked}
                            onRowClicked={onUserRowClicked}
                            stopEditingWhenCellsLoseFocus={true}
                            editType="fullRow"
                            animateRows={true}
                            suppressClickEdit={true}
                            columnDefs={userColDefs}
                            defaultColDef={userDefaultColDef}
                            rowSelection={'single'}
                            onGridReady={onUserGridReady}
                            rowData={userRowData}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrganizationDetailView;