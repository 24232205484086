import React, { useState,useRef,useEffect } from 'react';
import logo from '../../../images/LOCLOGOBLK1.png';
import toolsLogo from '../../../svg/gldsToolsLogoD.svg';
import GLDSFormModal from '../../GLDSFormModal/GLDSFormModal';
import StatTile from '../Components/StatTile/StatTile';
import './routeCss/dashboardView.css';
import AddUserForm from './../Forms/AddUserForm/AddUserForm';
import AddOrganizationForm from '../Forms/AddOrganizationForm/AddOrganizationForm';
import AddProductForm from '../Forms/AddProductForm/AddProductForm';
import AddAgentForm from '../Forms/AddAgentForm/AddAgentForm';
import { createFormDataObj } from '../../../Utilities';

import Button from '../../Button';
import { useLocalStorage } from '../../../useLocalStorage';
import CreateProductForm from '../Forms/CreateProduct/CreateProductForm';


const DashboardView = () => {
    const [session, setSession] = useLocalStorage('session')
    const [userId, setUserId] = useLocalStorage('uid');
    const [formModalShowing, setFormModalShowing] = useState(false);    
    const [modal, setModal] = useState();
    const [orgCount, setOrgCount] = useState(0);
    const [agentsCount, setAgentsCount] = useState(0);
    const [productsCount, setProductsCount] = useState(0);
    const [userCount, setUserCount] = useState(0);
    const [licenseCount, setLicenseCount] = useState(0);


    const dashboardViewRef = useRef(null);

    useEffect(() => {
        let fdorg = createFormDataObj({
            action: 'list_organizations',
            session,
            uid: userId,
        });
        fetch(window.API_URL, {
            method: 'POST',
            body: fdorg
        })
        .then(response => response.json())
        .then(result => {
            if (result.success === true){
                setOrgCount(result.data.length);
            }            
        })
        .catch(error => {
            console.error('Error:',error);
        })
        let fdagn = createFormDataObj({
            action: 'list_agents',
            session,
            uid: userId,
        });
        fetch(window.API_URL, {
            method: 'POST',
            body: fdagn
        })
        .then(response => response.json())
        .then(result => {
            if (result.success === true){
                setAgentsCount(result.data.length);
            }            
        })
        .catch(error => {
            console.error('Error:',error);
        })

        let fdusr = createFormDataObj({
            action: 'list_users',
            session,
            uid: userId,
        });
        fetch(window.API_URL, {
            method: 'POST',
            body: fdusr
        })
        .then(response => response.json())
        .then(result => {
            if (result.success === true){
                setUserCount(result.data.length);
            }            
        })
        .catch(error => {
            console.error('Error:',error);
        })

        let fdprd = createFormDataObj({
            action: 'list_products',
            session,
            uid: userId,
        });
        fetch(window.API_URL, {
            method: 'POST',
            body: fdprd
        })
        .then(response => response.json())
        .then(result => {           
            if (result.success === true){
                setProductsCount(result.data.length);
            }            
        })
        .catch(error => {
            console.error('Error:',error);
        })

        let fdlic = createFormDataObj({
            action: 'list_licenses',
            session,
            uid: userId,
        });
        fetch(window.API_URL, {
            method: 'POST',
            body: fdlic
        })
        .then(response => response.json())
        .then(result => {
            if (result.success === true){
                setLicenseCount(result.data.length);
            }            
        })
        .catch(error => {
            console.error('Error:',error);
        })
    },[]); 
    
    const modalAction = (formData,type) => {
        if(!formData) { setFormModalShowing(false); return;}
        let fd = createFormDataObj({...formData,...{action:type,uid:userId,session:session}});
        console.log(`Add ${type} modal action`);
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => {
            if(result.success === true){
                console.log(result.message);
                return;
            }
            console.log(result.message);
        })
        .catch(error => {
            console.error('Error:',error);
        })
        setFormModalShowing(false);
    }

    const closeModal = () => {
        setFormModalShowing(false);
    }

    const modalForType = (type) => {
        let tmp = type.toLowerCase();
        switch (type.toLowerCase()) {
            case 'user':
                return (
                    <GLDSFormModal
                    top={dashboardViewRef.current.scrollTop}
                    title={'Create User'}
                    form={
                        <AddUserForm                           
                            submitAction={modalAction} 
                        />
                    }
                    closeAction={setFormModalShowing}
                />
                )
            case 'organization': 
                return (
                    <GLDSFormModal
                    top={dashboardViewRef.current.scrollTop}
                    title={'Create Organization'}
                    form={
                        <AddOrganizationForm
                            submitAction={modalAction} 
                        />
                    }
                    closeAction={setFormModalShowing}
                />
                )
            case 'product': 
                return (
                    <GLDSFormModal
                    top={dashboardViewRef.current.scrollTop}
                    title={'Create a new product'}
                    form={         
                                       
                        <CreateProductForm
                            submitAction={modalAction} 
                        />
                    }
                    closeAction={setFormModalShowing}
                />
                )
            case 'agent': 
                return (
                    <GLDSFormModal
                    top={dashboardViewRef.current.scrollTop}
                    title={'Create Agent'}
                    form={
                        <AddAgentForm
                            submitAction={modalAction} 
                        />
                    }
                    closeAction={setFormModalShowing}
                />
                )
            default:
                break;
        }
    }

    const statTileAction = (type) => {
        console.log(type);
        console.log(`Stat tile ${type} action`);
        setModal(modalForType(type));
        setFormModalShowing(true);
    }

    

    return (
        <div ref={dashboardViewRef} className='dashboard__view'>

            {formModalShowing && (modal)}

            <div className='dashboard__head'>
                <div>
                    <img src={toolsLogo} alt="Glds Tools logo image" width='40%' />
                </div>
                <h1>Dashboard</h1>
            </div>
            <div className='stats__row'>

                <StatTile statType={'Organization'} statCount={orgCount} statAction={statTileAction} />
                <StatTile statType={'Agent'} statCount={agentsCount} statAction={statTileAction} />
                <StatTile statType={'Product'} statCount={productsCount} statAction={statTileAction} />
                <StatTile statType={'User'} statCount={userCount} statAction={statTileAction} />
                <StatTile statType={'License'} statCount={licenseCount} statAction={statTileAction} />

            </div>
            
        </div>
    );
}

export default DashboardView;