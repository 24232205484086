import * as Icon from 'react-bootstrap-icons';
import './TileOptionOne.css';


function TileOptionOne({ title,badgeCount,status,link,icon }) {
    return (
      <div className="tileoptionone">
            {(badgeCount > 0) ? <div className="notification-badge"><span>{badgeCount}</span></div> : ""}
            
            <div className="left-nav-menu">
                <div className="left-edge"></div>
                <div className="left-nav-btn">
                    <span title="View App Info">
                        <Icon.InfoCircle size={16} />                        
                    </span>
                    <span title="License Status">
                        <Icon.Key size={16} />                        
                    </span>
                    <span title="View Status Messages">
                        <Icon.ChatSquare size={16} />                        
                    </span>
                    <span title="Edit Users">
                        <Icon.Person size={16} />                        
                    </span>
                </div>
            </div>
            <div className="tile-content">
                <div className="tile-heading">
                    <h6>{title}</h6>
                </div>
                <div className="tile-content-body">
                    <span title="Launch App">                    
                        <span  onClick={() => link ? window.open(link, '_blank') : ""}>
                            {icon}
                        </span>
                    </span>
                </div>
            </div>
            
            <div className={`blob ${status}`} title={status}></div>
            
            
      </div>
    );
  }
  
  export default TileOptionOne;