import React, { useRef,useEffect,useState,useContext } from 'react';
import { Save,XCircle } from 'react-bootstrap-icons';
import { createFormDataObj } from '../../../../Utilities';
import { AppContext } from '../../../../AppContext/AppContext';
import './AddAgentForm.css';
import Button from '../../../Button';



const AddAgentForm = ({ submitAction,altAction }) => {
    const { state } = useContext(AppContext);
    const [orgs, setOrgs] = useState([]);
    const orgSelectRef = useRef(null);
    const agentIdTFRef = useRef(null);

    useEffect(() => {
        let fd = createFormDataObj({
            action: 'list_organizations',
            session: state.sid,
            uid: state.uid
        });
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => {                       
            if(result.success === true){                                                
                if (result.data.length > 0) {                    
                    setOrgs(result.data);
                }                
            }            
        })
        .catch(error => {
            console.error('Error:',error);
        })
    },[])

    const createAgentIdClickEvent = (e) => {
        console.log(e);
        console.log('Create agent id button clicked');
        if (orgSelectRef.current.selectedOptions[0].value.toLowerCase() === 'default') {
            alert('Must choose an organization to create an agent');
            orgSelectRef.current.focus();
            return;
        }        
        e.preventDefault();
        let fd = createFormDataObj({
            "action": 'create_agent',
            "uid": state.uid,
            "session": state.sid,
            "org_id": orgSelectRef.current.selectedOptions[0].value
        })    
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => { 
            console.table(`API RESPONSE: Success=${result.success}, Message=${result.message}`);                   
            if(result.success === true){
                agentIdTFRef.current.value = result.data.agent;
                return;
            }
        })
        .catch(error => {
            console.error('Error:',error);
        })

    }
    const closeModalForm = e => {
        console.log(e);
        console.log('Add user form close form modal method');
        if (altAction) {
            altAction();
            return;
        }
        submitAction();
    }
    const submitForm = e => {
        e.preventDefault();
        const fd = {
        }
        if (submitAction){
            submitAction(fd,'create_agent');
        }
    }

    return (
        <form className="add__agent__modal__form">
            <div className='add__agent__form__container'>
                <div className="row">
                    <div className='col-3'></div>
                    <div className='col-6'>
                        <div className={'form-floating mb-3'}>
                            <select ref={orgSelectRef} className="form-select" aria-label="Select organization" >
                                <option value={'default'}>Select an organization</option>
                                {orgs.map((org,index) => {
                                    return (
                                        <option key={index} value={org.id}>{org.name}</option>
                                    )
                                })}                                    
                            </select>                            
                            <label htmlFor="org-state" aria-label='billing State'>Organization</label>
                        </div>                        
                        
                        <div className={"input-group input-group-lg mb-3"}>
                            <input ref={agentIdTFRef} type="text" className="form-control" aria-label="Organization agent id" aria-describedby="Organization create button" readOnly/>
                            <button className="btn btn-outline-primary" type="button" id="button-addon2" onClick={createAgentIdClickEvent}>Create</button>
                        </div>                   
                    </div>
                    <div className='col-3'></div>
                </div>
            </div>
            <div className='add__agent__form__btn__container'>
                <div className="row">
                    <div className='col-2'></div>
                    <div className="col-4">
                        <div className='add__agent__btn__container'>
                            <Button icon={<XCircle size={16} className='btn-icon' />} title={"Cancel"} className={"danger__button"} onClick={closeModalForm}/>                            
                        </div>
                    </div>
                    <div className="col-4">
                        <div className='add__agent__btn__container'>
                            <Button icon={<Save size={16} className='btn-icon' />} title={"Save"} className={"accept__button"} onClick={submitForm}/>
                        </div>                            
                    </div>
                    <div className='col-2'></div>
                </div>
            </div>
        </form>
    );
}

export default AddAgentForm;