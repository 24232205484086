import TileOptionOne from './Components/TileOptionOne/TileOptionOne';
import * as Icon from 'react-bootstrap-icons';
import './MenuTileContainer.css';

const menuTiles = [
  {
    title:"Pocket Reports",
    status:"app-active",
    icon: <Icon.ClipboardData size={55} className="icon-shadow" />,
    badge: 0,
    link: "https://reports.gldstools.com"
  },
  {
    title:"SMS Express",
    status:"app-deactive",
    icon: <Icon.Shop size={55} className="icon-shadow" />,
    badge: 1,
    link: ""
  },
  {
    title:"Not on File",
    status:"app-active",
    icon: <Icon.FileEarmarkX size={55} className="icon-shadow" />,
    badge: 0,
    link: ""
  },
  {
    title:"MI Liquor Management",
    status:"app-active",
    icon: <Icon.CupStraw size={55} className="icon-shadow" />,
    badge: 0,
    link: ""
  },
  {
    title:"DUFB",
    status:"app-deactive",
    icon: <Icon.CashStack size={55} className="icon-shadow" />,
    badge: 1,
    link: ""
  },
  {
    title:"Local Express",
    status:"app-active",
    icon: <Icon.Cart4 size={55} className="icon-shadow" />,
    badge: 0,
    link: ""
  },
  {
    title:"Learning Center",
    status:"app-active",
    icon: <Icon.Mortarboard size={55} className="icon-shadow" />,
    badge: 0,
    link: ""
  }
];



function MenuTileContainer() {
    return (
      <div className="menutilecontainer">
         {menuTiles.map((tile,index) => {
           return <TileOptionOne key={index} title={tile.title} status={tile.status} icon={tile.icon} link={tile.link} badgeCount={tile.badge} />
         })}
      </div>
    );
  }
  
  export default MenuTileContainer;