import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter,Routes,Route } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import AdminView from './Components/AdminView/AdminView';
import AppMenu from './Components/AppMenu/AppMenu';
import AgentsView from './Components/AdminView/routes/AgentsView';
import ProductsView from './Components/AdminView/routes/ProductsView';
import LicensesView from './Components/AdminView/routes/LicensesView';
import HomeView from './Components/AdminView/routes/HomeView';
import { AppContextProvider } from './AppContext/AppContext';
import DashboardView from './Components/AdminView/routes/DashboardView';
import OrganizationDetailView from './Components/AdminView/Views/OrganizationDetailView/OrganizationDetailView';
import UserDetailView from './Components/AdminView/Views/UserDetailView/UserDetailView';
import SoftwareDownloadView from './Components/SoftwareDownloadView/SoftwareDownloadView';
import LicenseDetailView from './Components/AdminView/Views/LicenseDetailView/LicenseDetailView';
import AgentDetailView from './Components/AdminView/Views/AgentDetailView/AgentDetailView';

// window.API_URL = "http://api.cfthree.com";
window.API_URL = "https://api.gldstools.com";

ReactDOM.render(
  <React.StrictMode>
      
      <AppContextProvider>
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="admin" element={<AdminView />}>
                <Route path="home" element={<HomeView />}>
                    <Route path=":view" element={<HomeView />} />
                </Route>
                <Route path="organizations" element={<OrganizationDetailView />} >
                    <Route path=":org_id" element={<OrganizationDetailView />} />
                </Route>

                <Route path="agents" element={<AgentDetailView />} >
                    <Route path=":id" element={<AgentDetailView />} />
                </Route>

                <Route path="products" element={<ProductsView />} />
                <Route path="users" element={<UserDetailView />} >                    
                    <Route path=":id" element={<UserDetailView />} />
                </Route>
                <Route path="licenses" element={<LicenseDetailView />} >
                    <Route path=":id" element={<LicenseDetailView />} /> 
                </Route>
                <Route path="softwareDownload" element={<SoftwareDownloadView />} />
                <Route index element={<DashboardView />} />
            </Route>
            <Route path="apps" element={<AppMenu />}>
            </Route>
            <Route
                path="*"
                element={
                    <main style={{ padding: "1rem" }}>
                        <p>There's nothing here!</p>
                    </main>
                }
            />
        </Routes>
    </BrowserRouter>  
    </AppContextProvider>  
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
