import React, { createContext, useReducer } from 'react';
import AppContextReducer from './AppContextReducer';


const initialState = {
    sid: "-1",
    isAdmin: "",
    uid: "0"
}
    



export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppContextReducer, initialState);
    const value = {state, dispatch};
  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};
