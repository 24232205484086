import React, { useRef,useEffect } from 'react';
import { Save,XCircle } from 'react-bootstrap-icons';
import Button from '../../../Button';
import './CreateProductForm.css';

const inputBorderColor = '#ced4da';

const productTypes = [{value:"app",type:"Application"},{value:"srv",type:"Service"}];


const CreateProductForm = ({ formData,submitAction,altAction,readOnly }) => {
    const nameTFRef = useRef(null);
    const srpTFRef = useRef(null);
    const descTFRef = useRef(null);
    const productTypeSelectRef = useRef(null);

    useEffect(() => {
        if (!formData) return;
        nameTFRef.current.value = formData?.name ? formData.name : "";
        srpTFRef.current.value = formData?.srp ? formData.srp : "";
        descTFRef.current.value = formData?.description ? formData.description : "";
        nameTFRef.current.focus();
    },[]);
    const changeBorderColor = (e) => {
        e.target.style.borderColor = inputBorderColor;
    }
    const closeModalForm = e => {
        console.log(e);
        console.log('Add user form close form modal method');
        if (altAction) {
            altAction();
            return;
        }
        submitAction();
    }
    const validateForm = () => {
        let name = nameTFRef.current.value;
        let description = descTFRef.current.value;
        let status = true;
        if (name === "") {
            nameTFRef.current.style.borderColor = "red";
            nameTFRef.current.focus();
            status = false;
        }
        if (description === ""){
            descTFRef.current.style.borderColor = "red";
            descTFRef.current.focus();
            status = false;
        }
        return status;
    }
    const submitForm = e => {
        e.preventDefault();
        debugger;
        // if (!validateForm()) return;
        const fd = {
            name: nameTFRef.current.value,
            srp: srpTFRef.current.value,
            description: descTFRef.current.value,
            type: productTypeSelectRef.current.value
        }
        console.log(fd);
        // if (submitAction){
        //     submitAction(fd,'add_product');
        // }
    }


    return (
        <form id="create_product_modal-form">

            <div className='form-row'>

                <div className='form-row-col product_details-column'>
                    <div className={'form-floating pt-2 pb-2'}>
                        <input ref={nameTFRef} type="text" className="form-control" id="product-name" placeholder="Product Name" onChange={changeBorderColor} />
                        <label htmlFor="product-name" aria-label='Product name field'>Product name</label>
                    </div>
                    <div className={'form-floating pt-2 pb-2'}>
                        <input ref={srpTFRef} type="text" className="form-control" id="prod-srp" placeholder="Suggested retail price" />
                        <label htmlFor="prod-srp" aria-label='User email field'>Suggested retail price</label>
                    </div>                    
                    <div className={'form-floating pt-2 pb-2'}>
                        <select ref={productTypeSelectRef} className="form-select" aria-label="Select product type">
                            <option value={'default'}>Choose product type</option>
                            {productTypes.map((type,index) => {
                                return (
                                    <option key={`${index}-${type.value}`} value={type.value}>{type.type}</option>
                                )
                            })}                                    
                        </select>                            
                        <label htmlFor="org-state" aria-label='billing State'>Product Types</label>
                    </div>
                </div>
                <div className='form-row-col product_description-column'>
                    <div className={'form-floating'}>
                        {/* <input ref={nameTFRef} type="text" className="form-control" id="product-name" placeholder="Product Name" onChange={changeBorderColor} /> */}
                        <textarea ref={descTFRef} className="form-control" placeholder='Product Description' id="product-description" style={{height:"250px"}}></textarea>
                        <label htmlFor="product-description" aria-label='Product description field'>Product Description</label>
                    </div>                               
                </div>

            </div>

            <div className='button-row'>
                {/* FORM BUTTON ROW */}
                
                <div className='button-container'>
                    <Button icon={<XCircle size={16} className='btn-icon' />} title={"Cancel"} className={"danger__button"} onClick={closeModalForm}/>
                </div>    
                <div className='button-container'>
                    <Button icon={<Save size={16} className='btn-icon' />} title={"Save"} className={"accept__button"} onClick={submitForm}/> 
                </div>
                
            </div>

        </form>
    );
}

export default CreateProductForm;