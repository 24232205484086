import React, { useContext } from 'react';
import { PlusSquare } from 'react-bootstrap-icons';
import { AppContext } from '../../../../AppContext/AppContext';
import './StatTile.css';

const StatTile = ({ statType,statCount,statAction }) => {
    const { state } = useContext(AppContext);
    return (
        <div className="stat__tile">
            <div className="stat__tile__head">{statType}s</div>
            <div className="stat__tile__stat">
                <span>Count:</span><span>{statCount}</span>
            </div>
            <div className='stat__action'>
                <span>Add {statType}</span><span onClick={() => statAction(statType)}><PlusSquare size={24} /></span>
            </div>
        </div>
    );
}

export default StatTile;