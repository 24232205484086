import React, { useEffect, useRef } from 'react';
import { Check2Circle,XCircle,Save } from 'react-bootstrap-icons';
import Button from '../../../Button';
import './UpdatePasswordForm.css';

const UpdatePasswordForm = ({ formData,submitAction,altAction }) => {
    const nameTFRef = useRef(null);
    const passwordTFRef = useRef(null);
    const passwordConfirmTFRef = useRef(null);

    useEffect(() => {        
        passwordTFRef.current.focus();
        nameTFRef.current.value = formData.name;
    },[formData])

    const comparePasswords = () => {
        let pw = passwordTFRef.current.value;
        let pwc = passwordConfirmTFRef.current.value;
        if (pw === pwc){
            return true;
        }
        return false;
    }

    const changeBorderColor = (e) => {
        
    }
    const closeModalForm = (e) => {
        submitAction && submitAction();
    }
    const submitForm = (e) => {
        debugger;
        if (comparePasswords()){
            let fd = {
                update_id: formData.id,
                new_password: passwordConfirmTFRef.current.value
            }
            submitAction && submitAction(fd);
            return;
        }
    }

    return (
        <form className='user__detail__form'>
            <div className='user__detail__form__container'>
                <div className="row">
                    <div className='col-3'></div>
                    <div className='col-6'>
                        <div className={'form-floating mb-3'}>
                            <input type="text" ref={nameTFRef} className="form-control" id="user-name" placeholder="User Name" readOnly/>
                            <label htmlFor="user-name" aria-label='User name field'>Name</label>
                        </div>                           
                    </div>
                    <div className='col-3'></div>
                </div>
                <div className="row">
                    <div className='col-3'></div>
                    <div className='col-6'>
                        <div className={'form-floating mb-3'}>
                            <input type="password" ref={passwordTFRef} className="form-control" id="user-password" placeholder="New Password"/>
                            <label htmlFor="user-password" aria-label='User password field'>New Password</label>
                        </div>
                    </div>
                    <div className='col-3'></div>
                </div>
                <div className="row">
                    <div className='col-3'></div>
                    <div className='col-6'>
                        <div className={'form-floating mb-3'}>
                            <input type="text" ref={passwordConfirmTFRef} className="form-control" id="user-password-confirm" placeholder="Confirm Password"/>
                            <label htmlFor="user-password-confirm" aria-label='User confirm password field'>Confirm Password</label>
                        </div>                           
                    </div>
                    <div className='col-3'></div>
                </div>
                              
            </div>
            <div className='user__detail__form__btn__container'>
                <div className="row">
                    <div className='col-2'></div>
                    <div className="col-4">
                        <div className='user__detail__btn__container'>
                            <Button icon={<XCircle size={16} className='btn-icon' />} title={"Cancel"} className={"danger__button"} onClick={closeModalForm}/>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className='user__detail__btn__container'>
                            <Button icon={<Save size={16} className='btn-icon' />} title={"Update"} className={"accept__button"} onClick={submitForm}/> 
                        </div>                            
                    </div>
                    <div className='col-2'></div>
                </div>
            </div>
        </form> 
    );
};

export default UpdatePasswordForm;