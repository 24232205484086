import React, { useEffect,useRef } from 'react';
import './DropDownSelect.css';

function DropDownSelect({ direction,content,viewAction,selectAction,title }){
    const dropdownRef = useRef(null);

    const keyDownEvent = (e) => {
        console.log("Keydown event fired");
        console.log(e);
        if (e.code.toLowerCase() === "escape") {
            viewAction(false);
            window.removeEventListener('keydown',keyDownEvent);
        }        
    }
   

    useEffect(() => {
        window.addEventListener('keydown',keyDownEvent);
       
        return () => {
            window.removeEventListener('keydown',keyDownEvent);
        }
    },[]);

 

    

    return (
        <div ref={dropdownRef} className={`drop-down ${direction}`}>
            {title && <h4>Add</h4>}
            <ul>
                {content.map((link,index) => {
                    return <li key={index} onClick={() => selectAction({view: link.action, data: null})}>
                        <span>{link.icon}</span><span>{link.title}</span>
                    </li>
                })}
            </ul>           
        </div>
    )
}

export default DropDownSelect;