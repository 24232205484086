import './AppMenu.css';
import MenuTileContainer from './Components/MenuTileContainer/MenuTileContainer';
import MenuToolbar from './Components/MenuToolbar/MenuToolbar';

function AppMenu() {
    return (
      <div className="appmenu">
          <MenuToolbar />
          <MenuTileContainer />
      </div>
    );
  }
  
  export default AppMenu;