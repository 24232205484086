import React, { useContext } from 'react';
import { NavLink,Outlet,useNavigate } from "react-router-dom";
import { useLocalStorage } from '../../useLocalStorage';
import './AdminView.css';
import toolsLogo from '../../svg/gldsToolsLogoW.svg';
import { Building,PcDisplayHorizontal,Window,People,Key,BoxArrowLeft,Download } from 'react-bootstrap-icons';
import { AppContext, AppContextProvider } from '../../AppContext/AppContext';


const links = [
    {
        link: "organizations",
        title: "Organizations",
        icon: <Building size={18} />
    },
    {
        link: "agents",
        title: "Agents",
        icon: <PcDisplayHorizontal size={18} />
    },
    {
        link: "products",
        title: "Products",
        icon: <Window size={18} />
    },
    {
        link: "users",
        title: "Users",
        icon: <People size={18} />
    },
    {
        link: "licenses",
        title: "Licenses",
        icon: <Key size={18} />

    },
    {
        link: "softwareDownload",
        title: "Integrator",
        icon: <Download size={18} />
    },
];






const pj = require('../../../package.json');
const VERSION = pj.version;


const AdminView = () => {
    const { state,dispatch } = useContext(AppContext);
    const [session, setSession] = useLocalStorage('session');
    const [userId, setUserId] = useLocalStorage('uid');
    const [privilage, setPrivilage] = useLocalStorage('privilage');
    let navigate = useNavigate();
    
    const checkAuth = () => {
        if (userId === '0' || session === '-1' || privilage === "N") {         
            window.location.href = "https://my.gldstools.com";
            return;
        }
    }
    checkAuth();

    
    const navLogoClickAction = (e) => {
        navigate("/admin");
    }
    const logoutEvent = () => {
        setSession("-1");
        setUserId("0");
        setPrivilage("N");
        window.location.href="https://my.gldstools.com";
    }
  
  return (  

  
    <div className="admin__view">   
        <nav className='left__nav'>
            <div className='left__nav__logo' onClick={navLogoClickAction}>
                <img src={toolsLogo} alt="" />            
            </div>
            <div className='left__nav__main'>
                <ul className="side__nav__ul">
                    {links.map((link,index) => {
                        return (
                            <li key={index}>
                                <NavLink
                                    
                                    style={({ isActive }) => ({
                                        display: "block",
                                        backgroundColor: isActive ? '#00a651' : "",
                                        boxShadow: isActive ? 'rgba(0, 0, 0, 0.651) 2px 2px 10px 1px inset, rgba(50, 205, 50, 0.233) -2px -2px 10px 1px inset' : "",
                                        borderBottom: isActive ? '1px solid var(--primary-lite-color)' : "",
                                        color: isActive ? "snow" : "snow",
                                        width: "100%",
                                        height: '100%'

                                    })}
                                    to={link.link === "softwareDownload" ? `/admin/${link.link}` : `/admin/home/${link.link}`}
                                    key={index}
                                >
                                    <div style={{display:"flex",gap:'0.5em',alignItems:"center",width:"100%",height:"100%",padding: '0.5em'}}>
                                        {link.icon}
                                        {link.title}
                                    </div>
                                </NavLink>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className="left__nav__footer">
                <div className='log__out__link' onClick={logoutEvent}>
                    <div>
                    <BoxArrowLeft size={20} />
                    </div>
                    <div>
                        Logout
                    </div>
                </div>
                
                <span>GLDS Admin Panel</span>
                <span>{`Version: ${VERSION}`}</span>
            </div>            
        </nav>
        <Outlet />
    </div>
    
  );
}

export default AdminView;
