import React, { useRef,useEffect } from 'react';
import { Save,XCircle } from 'react-bootstrap-icons';
import { states } from '../../../../Utilities'
import Button from '../../../Button';
import './AddOrganizationForm.css';

const inputBorderColor = '#ced4da';

const AddOrganizationForm = ({ formData,submitAction,altAction,readOnly }) => {
    const orgNameTFRef = useRef(null);
    const addressTFRef = useRef(null);
    const cityTFRef = useRef(null);
    const stateTFRef = useRef(null);
    const zipcodeTFRef = useRef(null);
    const nameTFRef = useRef(null);
    const phoneTFRef = useRef(null);
    const emailTFRef = useRef(null);

    useEffect(() => {
        if (!formData) return;
        orgNameTFRef.current.value = formData?.name ? formData.name : "";
        addressTFRef.current.value = formData?.address ? formData.address : "";
        cityTFRef.current.value = formData?.city ? formData.city : "";
        stateTFRef.current.value = formData?.state ? formData.state : "";
        zipcodeTFRef.current.value = formData?.zip ? formData.zip : "";
        nameTFRef.current.value = formData?.contact ? formData.contact : "";
        emailTFRef.current.value = formData?.email ? formData.email : "";
        phoneTFRef.current.value = formData?.phone ? formData.phone : "";
        orgNameTFRef.current.focus();
    },[]);

    const changeBorderColor = (e) => {
        e.target.style.borderColor = inputBorderColor;
    }
    const validateForm = () => {
        let orgName = orgNameTFRef.current.value;
        let address = addressTFRef.current.value;
        let city = cityTFRef.current.value;
        let province = stateTFRef.current.value;
        let postal = zipcodeTFRef.current.value;
        let name = nameTFRef.current.value;
        let phone = phoneTFRef.current.value;
        let email = emailTFRef.current.value;
        let status = true;
        
        if (orgName === "") {
            orgNameTFRef.current.style.borderColor = "red";
            orgNameTFRef.current.focus();
            status = false;
        }
        if (address === "") {
            addressTFRef.current.style.borderColor = "red";
            addressTFRef.current.focus();
            status = false;
        }
        if (city === "") {
            cityTFRef.current.style.borderColor = "red";
            cityTFRef.current.focus();
            status = false;
        }
        if (province === "") {
            stateTFRef.current.style.borderColor = "red";
            stateTFRef.current.focus();
            status = false;
        }
        if (postal === "") {
            zipcodeTFRef.current.style.borderColor = "red";
            zipcodeTFRef.current.focus();
            status = false;
        }
        if (name === "") {
            nameTFRef.current.style.borderColor = "red";
            nameTFRef.current.focus();
            status = false;
        }
        if (email === ""){
            emailTFRef.current.style.borderColor = "red";
            emailTFRef.current.focus();
            status = false;
        }
        if (phone === ""){
            phoneTFRef.current.style.borderColor = "red";
            phoneTFRef.current.focus();
            status = false;
        }
        return status;
    }
    const closeModalForm = (e) => {
        console.log(e);
        console.log('Close add organization modal form');
        if (altAction) {
            altAction();
            return;
        }
        submitAction();
    }
    const submitForm = (e) => {
        console.log(e);
        console.log('Submit add organization modal form');
        e.preventDefault();
        if (!validateForm()) return;
        const fd = {
            name: orgNameTFRef.current.value,
            address: addressTFRef.current.value,
            city: cityTFRef.current.value,
            state: stateTFRef.current.value,
            zip: zipcodeTFRef.current.value,
            contact: nameTFRef.current.value,
            email: emailTFRef.current.value,
            phone: phoneTFRef.current.value
        }
        if (submitAction){
            submitAction(fd,'create_organization');
        }        
    }

    return (
        <form>
            <div className="row">
                <div className='col-3'></div>
                <div className='col-6'>
                    <div className={'form-floating mb-3'}>
                        <input ref={orgNameTFRef} type="text" className="form-control" id="org-name" placeholder="Oganization name" onChange={changeBorderColor} />
                        <label htmlFor="org-name" aria-label='Organization name'>Organization name</label>
                    </div>
                </div>
                <div className='col-3'></div>
            </div>
            <div className="row">
                <div className='col-1'></div>
                <div className="col-5">
                    <fieldset>
                        <legend>Billing Information</legend>
                        <div className={'form-floating mb-3'}>
                            <input ref={addressTFRef} type="text" className="form-control" id="org-address" placeholder="Address" onChange={changeBorderColor} />
                            <label htmlFor="org-address" aria-label='Billing Address'>Address</label>
                        </div>
                        <div className={'form-floating mb-3'}>
                            <input ref={cityTFRef} type="text" className="form-control" id="org-city" placeholder="City" onChange={changeBorderColor} />
                            <label htmlFor="org-city" aria-label='Billing City'>City</label>
                        </div>
                        <div className={'form-floating mb-3'}>
                            <select ref={stateTFRef} defaultValue={states[22]} className="form-select" aria-label="Select billing state" onChange={changeBorderColor} >
                                {states.map((usState,index) => {
                                    return (
                                        <option key={index} value={usState}>{usState}</option>
                                    )
                                })}                                    
                            </select>
                            
                            <label htmlFor="org-state" aria-label='billing State'>State</label>
                        </div>
                        <div className={'form-floating mb-3'}>
                            <input ref={zipcodeTFRef} type="text" className="form-control" id="org-zipcode" placeholder="Zipcode" onChange={changeBorderColor} />
                            <label htmlFor="org-zipcode" aria-label='Billing Zipcode'>Zipcode</label>
                        </div>
                    </fieldset>
                </div>
                <div className="col-5">
                    <fieldset>
                        <legend>Contact Information</legend>
                        <div className={'form-floating mb-3'}>
                            <input ref={nameTFRef} type="text" className="form-control" id="contact-name" placeholder="Name" onChange={changeBorderColor} />
                            <label htmlFor="contact-name" aria-label='Contact Name'>Name</label>
                        </div>
                        <div className={'form-floating mb-3'}>
                            <input ref={phoneTFRef} type="text" className="form-control" id="contact-name" placeholder="Phone" onChange={changeBorderColor} />
                            <label htmlFor="contact-name" aria-label='Contact Phone'>Phone</label>
                        </div>
                        <div className={'form-floating mb-3'}>
                            <input ref={emailTFRef} type="email" className="form-control" id="contact-email" placeholder="Email" onChange={changeBorderColor} />
                            <label htmlFor="contact-email" aria-label='Contact Email'>Email</label>
                        </div>
                        <div className={'form-floating mb-3 hidden'}>
                            <input type="text" className="form-control" id="contact-email" placeholder="Email" readOnly/>
                            <label htmlFor="contact-email" aria-label='Contact Email'>Email</label>
                        </div>
                    </fieldset>
                </div>
                <div className='col-1'></div>
            </div>
            <div className="row">
                <div className='col-2'></div>
                <div className="col-4">
                    <div className='add__org__btn__container'>
                        <Button icon={<XCircle size={16} className='btn-icon' />} title={"Cancel"} className={"danger__button"} onClick={closeModalForm}/>                        
                    </div>
                </div>
                <div className="col-4">
                    <div className='add__org__btn__container'>
                        <Button icon={<Save size={16} className='btn-icon' />} title={"Save"} className={"accept__button"} onClick={submitForm}/>
                    </div>                            
                </div>
                <div className='col-2'></div>
            </div>
        </form>
    );
}

export default AddOrganizationForm;