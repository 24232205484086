import React, { useRef,useState,useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useLocalStorage } from '../../../../useLocalStorage';
import { createFormDataObj,apiDataFetch } from '../../../../Utilities';
import { Gear,Pencil,XCircle,Save } from 'react-bootstrap-icons';
import WEButton from '../../../../ui/WEButton/WEButton';
import DropDownSelect from '../../../DropDownSelect/DropDownSelect';
import "./AgentDetailView.css";


const dropdownContent = [
    {
        action: 'edit',
        title: 'Edit',
        icon: <Pencil size={18} />
    }
];

const AgentDetailView = () => {
    const [session, setSession] = useLocalStorage('session');
    const [userId, setUserId] = useLocalStorage('uid');
    const urlParams = useParams();

    const [agentDetails, setAgentDetails] = useState({
        agent: "",
        id: "",
        location: "",
        org_id: "",
        org_name: "",
        store: "",
        weekstart: "",
    })
    const [storeName, setStoreName] = useState('');
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(true);

    const hostNameRef = useRef(null);
    const hostpwRef = useRef(null);
    const inPathRef = useRef(null);
    const outPathRef = useRef(null);
    const arcPathRef = useRef(null);
    const navIconRef = useRef(null);
    const portRef = useRef(null); 

    // Data fetching methods
    /**
     *  Fetch call to get license from the id
     *  passed in from the URL
     * 
     *  calls 
     *      @function setViewData()
     *  to set License data upon successful return
     *  return @object
     *      id,
     *      num_seats,
     *      org_id,
     *      product_id
     *      seats,
     *          total,
     *          used,
     *          users
     *      used_seats          
     **/
     const fetchAgentForId = (_agentId) => {
        let fd = createFormDataObj({
            action: 'get_agent',
            session,
            uid: userId,
            agent: _agentId
        });

        apiDataFetch(window.API_URL,{method:"POST",body:fd}).then(result => {
            console.log('Organization detail view');
            console.log(result);
            if (result.success === true){         
                debugger;   
                setAgentDetails({...agentDetails,...result.data});
            } 
        }).catch(error => {
            console.error('Error:',error);
        })
    }

    useEffect(() => {
        fetchAgentForId(urlParams.id)        
    },[urlParams.id]);




     // toggles the dropdown menu when clicking the gear icon
     const toggleDropdownMenu = (e) => {
        setIsDropdownVisible(isDropdownVisible ? false:true);
        navIconRef.current.classList.toggle('active');
    }
    // handles the dropdown menus selection event
    const dropdownAction = (e) => {
        switch (e.view) {
            case "edit":
                setIsReadOnly(!isReadOnly);
            default:
                break;
        }
    }

    const endEditEvent = (e) => {
        // setIsReadOnly(true);
        // setListUsers(licensedUsers);
        

        // let fd = createFormDataObj({
        //     action: "update_license",
        //     session,
        //     uid: userId,
        //     update_id: viewData.id,
        //     seats: parseInt(viewData.seats.total)
        // });
        // apiDataFetch(window.API_URL,{method:"POST",body:fd}).then(result => {
        //     if (result.success === true){
        //         setIsReadOnly(true);
        //         fetchLicenseForId(viewData.id);
        //     }    
        // }).catch(error => {
        //     console.error('Error:',error);
        // })
    }
    const cancelEditEvent = (e) => {
        // setIsReadOnly(true);
        // setListUsers(licensedUsers);
    }


    return (
        <div className="agent__detail-view" id="agent-detail-view">
        <nav>            
            <div className='nav__row'>
                <div ref={navIconRef} className='nav__icon' onClick={toggleDropdownMenu}>
                    <span><Gear size={35} /></span>
                    {isDropdownVisible &&  (
                        <DropDownSelect direction={"right"} viewAction={setIsDropdownVisible} content={dropdownContent} selectAction={dropdownAction} />
                    )}
                </div>
            </div>
        </nav>
        <div className='title'>
            <h2>Agent Store: {agentDetails.store}</h2>
        </div>
        <div className='form'>
            <form>
                <div className={"row p-3"} style={{height:'100%'}} >
                    {/* Left side text input column */}
                    <div className={'col-6'}>
                        <div className={'form-floating mb-3'}>
                            <input
                                ref={hostNameRef}
                                type="text"
                                className="form-control"
                                id="hostname-tf"
                                placeholder="Hostname"
                                readOnly={true}
                            />
                            <label htmlFor="hostname-tf" aria-label='Hostname text field'>Hostname</label>
                        </div> 
                        <div className={'form-floating mb-3'}>
                            <input
                                ref={hostpwRef}
                                type="text"
                                className="form-control"
                                id="host-pw-tf"
                                placeholder="Host Password"
                                readOnly={isReadOnly}                                    
                            />
                            <label htmlFor="host-pw-tf" aria-label='Host password field'>Host Password</label>
                        </div>
                        <div className={'form-floating mb-3'}>
                            <input
                                ref={inPathRef}
                                type="text"
                                className="form-control"
                                id="inbox-path-tf"
                                placeholder="Inbox Path"
                                readOnly={isReadOnly}                                    
                            />
                            <label htmlFor="inbox-path-tf" aria-label='Host inbox path field'>Inbox Path</label>
                        </div>
                        <div className={'form-floating mb-3'}>
                            <input
                                ref={outPathRef}
                                type="text"
                                className="form-control"
                                id="outbox-path-tf"
                                placeholder="Outbox Path"
                                readOnly={isReadOnly}                                    
                            />
                            <label htmlFor="outbox-path-tf" aria-label='Host outbox path field'>Outbox Path</label>
                        </div>
                        <div className={'form-floating mb-3'}>
                            <input
                                ref={arcPathRef}
                                type="text"
                                className="form-control"
                                id="archive-path-tf"
                                placeholder="archive Path"
                                readOnly={isReadOnly}                                    
                            />
                            <label htmlFor="archive-path-tf" aria-label='Host archive path field'>Archive Path</label>
                        </div>
                        <div className={'form-floating mb-3'}>
                            <input
                                ref={portRef}
                                type="text"
                                className="form-control"
                                id="port-tf"
                                placeholder="Host Port"
                                readOnly={isReadOnly}                                    
                            />
                            <label htmlFor="host-port-tf" aria-label='Host port field'>Host Port</label>
                        </div>
                    </div>
                    
                    {/* Right side agent assignment column */}
                    <div className='col-6 px-3' >
                        
                        <div className='connection_detail_panel'>
                            <div>Store ID: {agentDetails.agent}</div>
                            <div>App key: 88871ff3cd85e6c359b5cf73c0a404ed94431e29a2a2dcd458edcceab14346affd574361f825abf7c1b0e58a06c24adc3d55bc58c76fa8b8afa1cf3c71fbe0e8</div>
                            <div>Store secret: OWl5V0t3TzBJMmtsaUZDdm5mRlpDZz09</div>
                        </div>
                        
                    </div>                        
                </div>
            </form>
        </div>

        {!isReadOnly && <div className='btm__nav'>
            <div className="row">
                <div className='col-2'></div>
                <div className="col-4">
                    <div className='add__user__btn__container'>
                        <WEButton btnColor='#DA2C38' disabled={disabledBtn} onClick={cancelEditEvent} type={'outline'}>
                            <div className='btn-content cancel'>
                                <span><XCircle size={18} /></span>
                                <span>Cancel</span>
                            </div>
                        </WEButton>
                    </div>
                </div>
                <div className="col-4">
                    <div className='add__user__btn__container'>
                        <WEButton btnColor='#00a651' disabled={disabledBtn} onClick={endEditEvent} type={'outline'}>
                            <div className='btn-content save'>
                                <span><Save size={18} /></span>
                                <span>Save</span>
                            </div>
                        </WEButton>
                    </div>                            
                </div>
                <div className='col-2'></div>
            </div>
        </div>}

        </div>

    );
};

export default AgentDetailView;