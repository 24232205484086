import React, { useEffect, useRef,useState } from 'react';
import { Save,XCircle } from 'react-bootstrap-icons';
import Button from '../../../Button';
import SquareCheckboxInput from '../../../SquareCheckboxInput/SquareCheckboxInput';
import { createFormDataObj } from '../../../../Utilities';
import './AddUserForm.css';
import { useLocalStorage } from '../../../../useLocalStorage';

const inputBorderColor = '#ced4da';

const AddUserForm = ({ formData,submitAction,altAction,readonly }) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [session, setSession] = useLocalStorage('session')
    const [userId, setUserId] = useLocalStorage('uid');
    const [orgs, setOrgs] = useState([]);
    const nameTFRef = useRef(null);
    const emailTFRef = useRef(null);
    const passwordTFRef = useRef(null);
    const isAdminTFRef = useRef(null);
    const orgSelectRef = useRef(null);

    useEffect(() => {
        let fd = createFormDataObj({
            action: 'list_organizations',
            session: session,
            uid: userId
        });
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => {                       
            if(result.success === true){                                                
                if (result.data.length > 0) {                    
                    setOrgs(result.data);
                }                
            }            
        })
        .catch(error => {
            console.error('Error:',error);
        })
    },[])

    useEffect(() => {
        if (!formData) { nameTFRef.current.focus(); return;}
        if (formData?.org_id) { nameTFRef.current.focus(); return; }
        nameTFRef.current.value = formData?.name ? formData.name : "";
        emailTFRef.current.value = formData?.email ? formData.email : "";            
        
        if(!readonly){
            passwordTFRef.current.value = "";
            setIsAdmin(formData?.isAdmin === 'Y' ? true : false);
            
        }
        isAdminTFRef.current.value = formData?.isAdmin === 'Y' ? "Yes" : "No";
        
    },[]);
    const changeBorderColor = (e) => {
        e.target.style.borderColor = inputBorderColor;
    }
    const closeModalForm = e => {
        console.log(e);
        console.log('Add user form close form modal method');
        if (altAction) {
            altAction();
            return;
        }
        submitAction();
    }
    const validateForm = () => {
        if (readonly) return false;
        let name = nameTFRef.current.value;
        let email = emailTFRef.current.value;
        let pass = passwordTFRef.current.value;
        if (name === "") {
            nameTFRef.current.style.borderColor = "red";
            nameTFRef.current.focus();
            return false;
        }
        if (email === ""){
            emailTFRef.current.style.borderColor = "red";
            emailTFRef.current.focus();
            return false;
        }
        if (pass === ""){
            passwordTFRef.current.style.borderColor = "red";
            passwordTFRef.current.focus();
            return false;
        }
        return true;
    }
    const submitForm = e => {
        e.preventDefault();
        if (!validateForm()){
            if (submitAction){
                submitAction();
            }
            return;
        }

        const fd = {
            "org_id": formData?.org_id ? formData.org_id : orgSelectRef.current.selectedOptions[0].value,
            name: nameTFRef.current.value,
            email: emailTFRef.current.value,
            isAdmin: isAdmin ? 'Y' : 'N',
            password: passwordTFRef.current.value
        }
        
        if (submitAction){
            submitAction(fd,'create_user');
        }
    }

    return (        
        <form className="add__user__modal__form">
            <div className='add__user__form__container'>
                <div className="row">
                    <div className='col-3'></div>
                    <div className='col-6'>
                        <fieldset>
                            {!formData?.org_id && <div className={'form-floating mb-3'}>
                                <select ref={orgSelectRef} className="form-select" aria-label="Select organization" >
                                    <option value={'default'}>Select an organization</option>
                                    {orgs.map((org,index) => {
                                        return (
                                            <option key={index} value={org.id}>{org.name}</option>
                                        )
                                    })}                                    
                                </select>                            
                                <label htmlFor="org-state" aria-label='billing State'>Organization</label>
                            </div>}
                            <div className={'form-floating mb-3'}>
                                <input ref={nameTFRef} type="text" className="form-control" id="user-name" placeholder="User Name" onChange={changeBorderColor} readOnly={readonly ? readonly : false} />
                                <label htmlFor="user-name" aria-label='User name field'>Name</label>
                            </div>
                            <div className={'form-floating mb-3'}>
                                <input ref={emailTFRef} type="email" className="form-control" id="user-email" placeholder="City" onChange={changeBorderColor} readOnly={readonly ? readonly : false} />
                                <label htmlFor="user-email" aria-label='User email field'>Email</label>
                            </div>
                            {readonly ? "" : <div className={'form-floating mb-3'}>
                                <input ref={passwordTFRef} type="password" className="form-control" id="user-password" placeholder="State" onChange={changeBorderColor} readOnly={readonly ? readonly : false} />
                                <label htmlFor="user-password" aria-label='User password field'>Passsword</label>
                            </div>}
                            {readonly ? 
                                <div className={'form-floating mb-3'}>
                                    <input ref={isAdminTFRef} type="text" className="form-control" id="is-admin" placeholder="Is Admin" onChange={changeBorderColor} readOnly={readonly ? readonly : false} />
                                    <label htmlFor="is-admin" aria-label='Is user admin'>Is Admin</label>
                                </div> : 
                                <div className={'d-flex form-floating mb-3 cb_field'}>
                                    <div className='add__user__form__admin__cb'>
                                        <div><h3>Is Admin</h3></div>
                                        <SquareCheckboxInput action={setIsAdmin} />
                                    </div>                                
                                </div>
                            }
                        </fieldset>
                    </div>
                    <div className='col-3'></div>
                </div>
            </div>
            <div className='add__user__form__btn__container'>
                <div className="row">
                    <div className='col-2'></div>
                    <div className="col-4">
                        <div className='add__user__btn__container'>
                            <Button icon={<XCircle size={16} className='btn-icon' />} title={"Cancel"} className={"danger__button"} onClick={closeModalForm}/>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className='add__user__btn__container'>
                            <Button icon={<Save size={16} className='btn-icon' />} title={readonly ? "Done" : "Save"} className={"accept__button"} onClick={submitForm}/>                            
                        </div>                            
                    </div>
                    <div className='col-2'></div>
                </div>
            </div>
        </form>
    );
}

export default AddUserForm;
