import './LoginMsgColumn.css';

function LoginMsgColumn() {
  return (
    <div className="login-p-col">
        <div className="login-p">
            <span>
                Please enter your email and password to login
            </span>
        </div>
    </div>
  );
}

export default LoginMsgColumn;