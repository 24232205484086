import React, { useState,useEffect,useRef } from 'react';
import { useParams } from "react-router-dom";
import { Gear,Lock, Pencil,XCircle,Save } from 'react-bootstrap-icons';
import { useLocalStorage } from '../../../../useLocalStorage';
import { createFormDataObj,apiDataFetch  } from '../../../../Utilities';
import loader from '../../../../svg/tail-spin.svg';
import GLDSFormModal from '../../../GLDSFormModal/GLDSFormModal';
import './UserDetailView.css';
import DropDownSelect from '../../../DropDownSelect/DropDownSelect';
import UpdatePasswordForm from '../../Forms/UpdatePasswordForm/UpdatePasswordForm';
import SquareCheckboxInput from '../../../SquareCheckboxInput/SquareCheckboxInput';
import Button from '../../../Button';
import WEButton from '../../../../ui/WEButton/WEButton';
import WECheckbox from '../../../WECheckbox/WECheckbox';


const dropdownContent = [
    {
        action: 'edit',
        title: 'Edit',
        icon: <Pencil size={18} />
    },
    {
        action: 'passwordUpdate',
        title: 'Update Password',
        icon: <Lock size={20} />
    }
];

const formatIsAdminValue = (_value) => {
    return (_value.toLowerCase() === "y") ? "Yes" : "No";
}


const UserDetailView = () => {
    // Session and current user local storage vars
    const [session, setSession] = useLocalStorage('session');
    const [userId, setUserId] = useLocalStorage('uid');

    // React Router useParams custom hook
    const urlParams = useParams();

    // useRef hook vars
    const navIconRef = useRef(null);
    const nameTFRef = useRef(null);
    const firstRenderRef = useRef(true);

    // Component state vars
    const [isLoading, setIsLoading] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isGlobalUser, setIsGlobalUser] = useState(false);
    const [userAgents, setUserAgents] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);    
    const [showPWModal, setShowPWModal] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [agents, setAgents] = useState([]);
    const [checkedStates, setCheckedStates] = useState([]);
    const [agentListItems, setAgentListItems] = useState([]);
    const [viewData, setViewData] = useState({
        id: "",
        name: '',
        email: '',
        isAdmin: ''
    })

    /**
     * fetch request to get user with the id from the url
     * specifical urlParams.id
     * 
     * sets viewData state var
     * 
     * @param {String} _userId 
     * @returns {
     *  id,
     *  name,
     *  email,
     *  org_id,
     *  isAdmin
     * }
     */
    const fetchUserWithID = (_userId) => {
        let fd = createFormDataObj({
            action: 'get_user',
            session,
            uid: userId,
            id: _userId
        });

        apiDataFetch(window.API_URL,{method:"POST",body:fd}).then(result => {
            console.log('Organization detail view');
            console.log(result);
            if (result.success === true){
                let usr = result.data[0];
                setViewData({...viewData,...usr});
            }   
        }).catch(error => {
            console.error('Error:',error);
        })
    }
    /**
     * fetches all the agents for the users
     * organization id
     * 
     * sets agents state var
     * sets checkedSstates array
     * 
     * @param {String} _orgId
     * @returns {Arrayy} Objects {
     *      id,
     *      org_id,
     *      agent,
     *      org_name,
     *      location,
     *      store,
     *      workstart
     * }
     */
    const fetchAgentsForOrgID = (_orgId) => {
        let fd = createFormDataObj({
            action: 'list_agents',
            session,
            uid: userId,
            org_id: _orgId
        });
        apiDataFetch(window.API_URL,{method:"POST",body:fd}).then(result => {
            console.table(`API RESPONSE: Success=${result.success}, Message=${result.message}`);                   
            if(result.success === true){
                console.log(result.message);
                console.table(result);
                setCheckedStates(new Array(result.data.length).fill(false));                
                setAgents(result.data);
                return;
            }
        }).catch(error => {
            console.log(error);
        })
    }
    /**
     * 
     * @param {String} _userId 
     */
    const fetchAgentsForUserID = (_userId) => {
        let fd = createFormDataObj({
            action: 'list_agents',
            session,
            uid: userId,
            user_id: _userId
        });
        apiDataFetch(window.API_URL,{method:"POST",body:fd}).then(result => {
            console.table(`API RESPONSE: Success=${result.success}, Message=${result.message}`);
            if(result.success === true){
                let res = [];
                console.log(result.message);
                console.table(result);
                let tmpArr  = new Array(agents.length).fill(false);
                for (let i = 0; i < agents.length; i++) {
                    const agent = agents[i];                   
                    for (let k = 0; k < result.data.length; k++) {
                        const element = result.data[k];
                        if (agent.id === element.id) {
                            tmpArr[i] = true;
                        }
                    }
                }
                setUserAgents([...result.data]);               
                setCheckedStates([...tmpArr]);
                setAgentListItems([...result.data]);
                return;
            }
        }).catch(error => {
            console.log(error);
        })
    }

    // get user for id on load
    useEffect(() => {
        fetchUserWithID(urlParams.id);
    },[urlParams.id]);
    // get the agents for the organization id of the current user
    useEffect(() => {
        if (firstRenderRef.current && viewData.id === "") return;
        fetchAgentsForOrgID(viewData.org_id);
        firstRenderRef.current = false;
    },[viewData]);
    // gets the agents associated with the current user's id
    useEffect(() => {
        if (firstRenderRef.current && agents.length === 0)return;
        fetchAgentsForUserID(viewData.id);
    },[agents]);


    // useEffect(() => {
    //     let userAgentsArr = [];
    //     if (isGlobalUser){
    //         userAgentsArr = agents.map(agent => agent.id);
    //     }

    //     let fd = createFormDataObj({
    //         action: 'update_user_agents',
    //         session,
    //         uid: userId,            
    //         update_id: parseInt(viewData.id),
    //         agents: JSON.stringify(userAgentsArr)
    //     });
    //     apiDataFetch(window.API_URL,{method:"POST",body:fd}).then(result => {
            
    //         if (result.success === true){
    //             // fetchLicenseForId(viewData.id);
    //             setAgentListItems(agents);
    //             // const intv = setTimeout(() => {
    //             //     li.classList.toggle('is-loading');
    //             //     bannerEle.firstElementChild.innerText = updatedCheckState ? "User removed!" : "User added";
    //             //     bannerEle.classList.toggle('is-success');
    //             //     bannerEle.classList.toggle('is-visible');
    //             //     const intv2 = setTimeout(() => {
    //             //         bannerEle.classList.toggle('is-success');
    //             //         bannerEle.classList.toggle('is-visible');
    //             //         // setDisabledBtn(false);
    //             //         clearTimeout(intv2);                        
    //             //     },1000);
    //             //     clearTimeout(intv);
    //             // },1000)
    //             return;
    //         }
    //     }).catch(error => {
    //         console.error('Error:',error);
    //     })
    // },[isGlobalUser])

    // toggles the dropdown menu when clicking the gear icon
    const toggleDropdownMenu = (e) => {
        setIsDropdownVisible(isDropdownVisible ? false:true);
        navIconRef.current.classList.toggle('active');
    }
    
    // handles the dropdown menus selection event
    const dropdownAction = (e) => {
        switch (e.view) {
            case "passwordUpdate":
                setShowPWModal(true);
                return;
            case "edit":
                setIsReadOnly(isReadOnly ? false:true);
                setAgentListItems(agents);
                nameTFRef.current.focus();
            default:
                break;
        }
    }

    const endEditEvent = (e) => {
        setIsReadOnly(true);
        let tmpName = viewData.name;
        let tmpEmail = viewData.email;
        let tmpIsAdmin = viewData.isAdmin;
        let tmpia = isAdmin;
        debugger;

    }
    const cancelEditEvent = (e) => {
        setIsReadOnly(true);
    }

    const modalSubmitAction = (_formData) => {
        setShowPWModal(false);
        let fd = createFormDataObj({
            action: 'update_password',
            session,
            uid: userId,
            update_id: _formData.update_id,
            new_password: _formData.new_password
        });
        fetch(window.API_URL, {
            method: 'POST',
            body: fd
        })
        .then(response => response.json())
        .then(result => {  
            console.log('User password upate response');
            console.log(result);            
            if (result.success === true){                
                               
            }            
        })
        .catch(error => {
            console.error('Error:',error);
        })
    }

   

    const checkboxClickEvent = (e,eleIndex) => {

        let checkedStatus = checkedStates[eleIndex];        
        let checkboxVal = e.target.dataset.value;

        let li = e.target.closest('li');
        let ele = li.querySelector('.license__user__item__loading');
        let loadingEle = li.querySelector('.license__user__item__loading');
        let bannerEle = li.querySelector('.license__user__item__banner');
        li.classList.toggle('is-loading');
        // setDisabledBtn(true);
        
        
        let updatedCheckState = checkedStates.map((check,index) => {
            return index === eleIndex ? !check : check;
        })
    
        if (checkedStatus){

            let tarr = userAgents.filter(agent => parseInt(agent.id) !== parseInt(checkboxVal));
            let reqArr = tarr.map(ta => ta.id);
            setUserAgents(tarr);
            debugger;
            setCheckedStates(updatedCheckState);
            let fd = createFormDataObj({
                action: 'update_user_agents',
                session,
                uid: userId,
                update_id: parseInt(viewData.id),
                agents: JSON.stringify(reqArr)
            });
            apiDataFetch(window.API_URL,{method:"POST",body:fd}).then(result => {
                if (result.success === true){
                    const intv = setTimeout(() => {
                        li.classList.toggle('is-loading');
                        bannerEle.firstElementChild.innerText = "License Removed!"
                        bannerEle.classList.toggle('success');
                        bannerEle.classList.toggle('is-visible');
                        const intv2 = setTimeout(() => {
                            bannerEle.classList.toggle('is-visible');
                            // setDisabledBtn(false);
                        },3000);
                        clearTimeout(intv);
                    },1000)
                    return;
                }
            });
            
            // let res = viewData.seats.users.filter(user => parseInt(user) === parseInt(checkboxVal));
            // debugger;
            // let tmpA = [];
            // if (res.length !== 0){
            //     res.forEach(user => {
            //         tmpA.push(user.id);
            //     });
            // }
        //     // setViewData({...viewData,seats:{...viewData.seats,users:tmpA}});
        //     // let filtered = licensedUsers.filter(fUser => parseInt(fUser.id) !== parseInt(checkboxVal));
        //     // setLicensedUsers(filtered);
        //     // fetchLicenseForId(viewData.id);
            return;
        }
        
        let tp = [...userAgents,agents.filter(agt => parseInt(agt.id) === parseInt(checkboxVal))[0]];
        let reqArr = tp.map(t => t.id);
        setUserAgents(tp);
        setCheckedStates(updatedCheckState);
        
        let fd = createFormDataObj({
            action: 'update_user_agents',
            session,
            uid: userId,            
            update_id: parseInt(viewData.id),
            agents: JSON.stringify(reqArr)
        });
        apiDataFetch(window.API_URL,{method:"POST",body:fd}).then(result => {
            
            if (result.success === true){
                // fetchLicenseForId(viewData.id);
                // setAgentListItems(res);
                const intv = setTimeout(() => {
                    li.classList.toggle('is-loading');
                    bannerEle.firstElementChild.innerText = updatedCheckState ? "User removed!" : "User added";
                    bannerEle.classList.toggle('is-success');
                    bannerEle.classList.toggle('is-visible');
                    const intv2 = setTimeout(() => {
                        bannerEle.classList.toggle('is-success');
                        bannerEle.classList.toggle('is-visible');
                        // setDisabledBtn(false);
                        clearTimeout(intv2);                        
                    },1000);
                    clearTimeout(intv);
                },1000)
                return;
            }
        }).catch(error => {
            console.error('Error:',error);
        })
        
        // let filtered = users.filter(fUser => parseInt(fUser.id) === parseInt(checkboxVal));
        // let tmpA = [...viewData.seats.users,...[parseInt(checkboxVal)]];
        // setViewData({...viewData,seats:{...viewData.seats,users:tmpA}});
        // setLicensedUsers([...licensedUsers,...filtered]);


        // let updatedCheckState = checkedStates.map((check,index) => {
        //     return index === eleIndex ? !check : check;
        // })
        // setCheckedStates(updatedCheckState);
    }

    const nameInputChangeEvent = (e) => {
        let tmp = {...viewData,name:e.target.value};
        setViewData(tmp);
    }

    const emailInputChangeEvent = (e) => {
        let tmp = {...viewData,email:e.target.value};
        setViewData(tmp);
    }
    console.log('UserDetailView.js checkedStates: '+JSON.stringify(checkedStates));
    return (
        <div className="user__detail-view" id="user-detail-view">
            {showPWModal && <GLDSFormModal title={'Update user password'} form={<UpdatePasswordForm formData={viewData} submitAction={modalSubmitAction}/>}/> }
            <nav>
                <div className='nav__row'>
                    <div ref={navIconRef} className='nav__icon' onClick={toggleDropdownMenu}>
                        <span><Gear size={35} /></span>
                        {isDropdownVisible &&  (
                            <DropDownSelect direction={"right"} viewAction={setIsDropdownVisible} content={dropdownContent} selectAction={dropdownAction} />
                        )}
                    </div>
                </div>
            </nav>
            <div className='title'>
                <h2>User: {viewData.name}</h2>
            </div>
            <div className='form'>
                <form>
                    <div className={"row p-3"} style={{height:'100%'}} >
                        {/* Left side text input column */}
                        <div className={'col-6'}>
                            <div className={'form-floating mb-3'}>
                                <input
                                    ref={nameTFRef}
                                    type="text"
                                    className="form-control"
                                    id="user-name"
                                    value={viewData.name}
                                    placeholder="User Name"
                                    readOnly={isReadOnly}
                                    onChange={nameInputChangeEvent}
                                />
                                <label htmlFor="user-name" aria-label='User name field'>Name</label>
                            </div> 
                            <div className={'form-floating mb-3'}>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="user-email"
                                    value={viewData.email}
                                    placeholder="City"
                                    readOnly={isReadOnly}
                                    onChange={emailInputChangeEvent}
                                />
                                <label htmlFor="user-email" aria-label='User email field'>Email</label>
                            </div>
                            {isReadOnly ? 
                                <div className={'form-floating mb-3'}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="is-admin"
                                        placeholder="Is Admin"
                                        value={formatIsAdminValue(viewData.isAdmin)}
                                        readOnly={isReadOnly ? isReadOnly : false}
                                    />
                                    <label htmlFor="is-admin" aria-label='Is user admin'>Is Admin</label>
                                </div> : 
                                <div className={'d-flex form-floating mb-3 cb_field'}>                                    
                                    <div className='add__user__form__admin__cb'>
                                        <div><h3>Is Admin</h3></div>
                                        <SquareCheckboxInput action={setIsAdmin} />
                                    </div>
                                </div>
                            }
                        </div>
                        
                        {/* Right side agent assignment column */}
                        <div className='col-6 px-3' >
                            <div className='user__agent__assign-view'>
                                {!isReadOnly && <div className='heading'>
                                    <h2>Assign user to agents</h2>
                                </div>}
                                
                                {!isReadOnly && <div className='option'>
                                    <div className={'d-flex form-floating mb-3 cb_field'}>
                                        {/* <div className='add__user__form__admin__cb'>
                                            <div><h3>Global User</h3></div>
                                            <SquareCheckboxInput action={setIsGlobalUser} />
                                        </div>                                 */}
                                        {/* <WECheckbox
                                            id="global-user"
                                            name={"global user"}
                                            checked={isGlobalUser}
                                            onChange={setIsGlobalUser}
                                        /> */}
                                    </div>
                                </div>}
                                <div className='body'>
                                    <div>
                                        <h4>Assigned agents</h4>
                                    </div>
                                    <ul>
                                        {agentListItems.map((agent,index) => {
                                            return (
                                                <li key={agent.id} className='license__user__item'>
                                                    
                                                    <div className='license__user__item__banner'>
                                                        <span></span>
                                                    </div>
                                                    <div className={`license__user__item__loading ${isLoading ? "is-loading" : ""}`}>
                                                        <img src={loader} />
                                                    </div>
                                                    <div className={`license__user__item__cb-wrapper ${!isReadOnly ? "" : "is-hidden"}`}>
                                                    
                                                        <label className="container" htmlFor={`custom-checkbox-${index}`} title="Add license">
                                                            <input
                                                                type="checkbox"
                                                                tabIndex="0"
                                                                id={`custom-checkbox-${index}`}
                                                                checked={checkedStates[index]}
                                                                onChange={(e) => checkboxClickEvent(e,index)}
                                                                data-value={agent.id}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                    <div className='license__user__item__details-wrapper'>
                                                        <span>
                                                            {agent.store}
                                                        </span>
                                                        <span>
                                                            {agent.location}
                                                        </span>
                                                    </div>
                                                    
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </form>
           </div>

           {!isReadOnly && <div className='btm__nav'>
                <div className="row">
                    <div className='col-2'></div>
                    <div className="col-4">
                        <div className='add__user__btn__container'>
                            <WEButton btnColor='#DA2C38' onClick={cancelEditEvent} type={'outline'}>
                                <div className='btn-content cancel'>
                                    <span><XCircle size={18} /></span>
                                    <span>Cancel</span>
                                </div>
                            </WEButton>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className='add__user__btn__container'>
                            <WEButton btnColor='#00a651'  onClick={endEditEvent} type={'outline'}>
                                <div className='btn-content save'>
                                    <span><Save size={18} /></span>
                                    <span>Save</span>
                                </div>
                            </WEButton>
                        </div>                            
                    </div>
                    <div className='col-2'></div>
                </div>
           </div>}

        </div>
    ); 
}
export default UserDetailView;
